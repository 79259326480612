import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux'
import reducers from './reducers'
import {legacy_createStore as createStore, applyMiddleware} from 'redux'
import promise from 'redux-promise'
import thunk from 'redux-thunk'
import multi from 'redux-multi'

import './index.css';
import './template/dependencies'

const store = applyMiddleware(multi, thunk, promise)(createStore)(reducers)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
