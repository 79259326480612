import { isNull } from "../../utils/util"

export function validate (values) {
    const errors = {}

    if (isNull(values.barrelId)) {
        errors.barrelId = 'Campo obrigatório'
    }

    if (isNull(values.tapTag)) {
        errors.tapTag = 'Campo obrigatório'
    } else if(values.tapTag.length > 6) {
        errors.tapTag = 'Campo deve conter no maximo 6 caracteres'
    }

    if (isNull(values.deviceTag)) {
        errors.deviceTag = 'Campo obrigatório'
    } else if(values.deviceTag.length > 8) {
        errors.deviceTag = 'Campo deve conter no maximo 8 caracteres'
    }

    // if (isNull(values.factor)) {
    //     errors.factor = 'Campo obrigatório'
    // } 
    
    return errors
}