import React, { Component } from 'react'
import MenuItem from './menuItem'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeCurrentUser, getUserData } from '../shared/users/userAction'

class Sidebar extends Component {

    constructor(props) {
        super(props)
        this.state = { currentUser: {fullName: '', email: ''}}
    }

    componentDidMount() {
        this.setState({currentUser: getUserData()})
    }

    render() {
        const { currentUser } = this.state
        const { isFullAdmin } = currentUser
        return (
            <aside className="main-sidebar sidebar-dark-primary elevation-4 sidebar">

                <span className="brand-link text-center app-header">
                    <span className="app-name">
                        <b>HAPPY<span className='txt-beer'>BEER</span></b>
                    </span>
                </span>

                <div className="sidebar">

                    <div className="app-user-info">
                        <i className="fas fa-user-circle icon-user"></i>  <br></br>                    
                        <span>
                            {currentUser.fullName.toLowerCase()}<br></br>
                            {currentUser.email.toLowerCase()}
                        </span>
                    </div>
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <MenuItem path='/' label='Dashboard' icon='th' />

                            {!isFullAdmin ? <MenuItem isMenuAdmin={isFullAdmin} path='barrels' label='Barris' icon='prescription-bottle' /> : false}
                            {!isFullAdmin ? <MenuItem isMenuAdmin={isFullAdmin} path='taps' label='Torneiras' icon='faucet' /> : false}
                            {!isFullAdmin ? <MenuItem isMenuAdmin={isFullAdmin} path='card' label='Cartão' icon='money-check-alt' /> : false}
                            {!isFullAdmin ? <MenuItem isMenuAdmin={isFullAdmin} path='cards' label='Cartões' icon='credit-card' /> : false}

                            {isFullAdmin ? <MenuItem isMenuAdmin={isFullAdmin} path='companies' label='Empresas' icon='th' /> : false}
                            {isFullAdmin ? <MenuItem isMenuAdmin={isFullAdmin} path='cards' label='Cartões' icon='th' /> : false}
                            {isFullAdmin ? <MenuItem isMenuAdmin={isFullAdmin} path='devices' label='Dispositivos' icon='th' /> : false}

                        </ul>
                    </nav>
                </div>
            </aside>
        )
    }
}

const mapStateToProps = state => ({ user: state.users.currentUser })
const mapDispatchToProps = dispatch => bindActionCreators({ changeCurrentUser }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
