import React, { Component } from 'react'
import Modal from 'react-modal';
import Spinner from '../../shared/spinners/spinner';

import './modal.css'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
export default class CustomModal extends Component {

  render() {
    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.close}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="content-modal">
          <div className='md-header'>
            <h5>
              <b>{this.props.title}</b>
            </h5>
          </div>

          <div className='md-content'>
            <p>{this.props.subtitle}</p>
          </div>
          <div className="md-footer">
            {
              this.props.isLoading ? <Spinner></Spinner>
                : <div>
                  <button onClick={this.props.close} type="button" className="btn btn-outline-dark buttons-action">Fechar</button>

                  <button onClick={this.props.confirm} type="button" className="btn btn-outline-success">Continuar</button>
                </div>
            }
          </div>
        </div>
      </Modal>
    )
  }
}