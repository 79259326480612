
import React, { Component } from 'react'
import ModalCustom from '../../../components/modals/CustomModal'
import {Shimmer} from '../../../components/loaders/shimmer'
import { toasterError, toasterSuccess } from '../../../shared/notifications/customToaster'
import { factorLastReading } from '../repositories/tapUserRepository'
import { updateFactor } from '../tapAction'

export default class FactorModal extends Component {

    constructor(props) {
        super(props)
        this.clickOpenModal = this.clickOpenModal.bind(this)
        this.updateFactor = this.updateFactor.bind(this)

        this.state = {
            isLoading: true,
            isLoadingButtonModal: false,
            lastConsume: 0,
            correctReading: 0,
        }

    }
    

    clickOpenModal() {
        this.setState({ isLoading: true, correctReading: 0 })
        factorLastReading(this.props.tapId)
            .then((resp) => {
                this.setState({ isLoading: false, lastConsume: resp.data.lastReading  })
            })
    }

    updateFactor() {
        const { correctReading, lastConsume } = this.state

        if (correctReading === 0) {
            toasterError()
        } else {
            this.setState({ isLoadingButtonModal: true  })
            const data = {
                correctReading: correctReading,
                lastReading: lastConsume
            }
            updateFactor(this.props.tapId, data)
            .then((_) => {
                this.setState({ isLoadingButtonModal: false })
                toasterSuccess()
            })
            .catch((_) => {
                this.setState({ isLoadingButtonModal: false, })
                toasterError()
            })

        }
    }

    changeValue(value) {
        this.setState({ correctReading: value  })
    }

    render() {
        const { isLoading, lastConsume, isLoadingButtonModal } = this.state
        return (
            <ModalCustom title={`Calcular fator - Torneira ${this.props.tag}`}
                openModalButtonLabel="Calcular Fator"
                key={this.props.tapId}
                tapId={this.props.tapId}
                clickButtonOpenModal={this.clickOpenModal}
                clickConfirm={this.updateFactor}
                isLoading={isLoadingButtonModal}
            >
                <form>
                    
                    <div style={{ color: 'black', textAlign: 'left' }} className="form-group">
                        <label for="exampleInputEmail1">Ultima Leitura</label>
                        {isLoading ? <Shimmer/>  : <input value={lastConsume} disabled type="number" className="form-control" />}
                    </div>
                    <div style={{ color: 'black', textAlign: 'left' }} className="form-group">
                        <label for="exampleInputEmail2">Leitura correta</label>
                        <input onChange={(e) => this.changeValue(e.target.value) } type="number" className="form-control" id="exampleInputEmail2" />
                    </div>
                </form>

            </ModalCustom>
        )

    }
}