import React from 'react';
import { TransactionType } from '../models/TransactionType';
import { formatReal, isNull } from '../../../utils/util';
import dayjs from 'dayjs';
import {Shimmer} from '../../../components/loaders/shimmer';
import ReactPaginate from 'react-paginate';

import './transactionsTable.css';

export const TransactionsTable = ({ isLoadingTable, handlePageClick, transactions }) => {

    const renderRows = () => {       
        if (transactions.content == null) return false
        return transactions.content.map(data => {
            return <tr key={data.id}>
                <td>{data.id}</td>
                <td>
                    {dayjs(data.createDate).format("DD/MM/YYYY HH:mm:ss")}
                </td>
                <td>{data.responsibleName}</td>
                <td>{formatReal(data.amount, {})}</td>
                <td>
                    {data.transactionType === TransactionType.OUTPUT ? <span className="badge bg-danger bg-type-balance">SAIDA</span>
                        : <span className="badge bg-success bg-type-balance">ENTRADA</span>}
                </td>
                <td>
                    {isNull(data.reason) ? false : <button type="button" className="btn btn-secondary"
                        data-toggle="tooltip" data-placement="right" title={data.reason}>
                        <i className="fas fa-comment"></i>
                    </button>}
                </td>
            </tr>
        })
    };

    return (
        <div className='row'>
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Movimentações</h3>
                    </div>

                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Data</th>
                                    <th>Responsável</th>
                                    <th>Valor</th>
                                    <th>Tipo</th>
                                    <th>Motivo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoadingTable ?
                                    <>
                                        <tr>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                        </tr>
                                        <tr>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                            <td><Shimmer /></td>
                                        </tr>
                                    </>
                                    : renderRows()}
                            </tbody>
                        </table>

                        <div className="card-footer clearfix pagination-transactions">
                            <ReactPaginate
                                nextLabel="próximo >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={transactions.totalPages}
                                previousLabel="< anterior"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  };
