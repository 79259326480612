import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {ContentHeader} from '../../components/contentHeader'
import { getAllCards, createCard, updateStatus } from './cardAction'
import {TableShimmer} from '../../components/tables/tableShimmer'
import CustomModal from '../../components/modals/modal'

import './cards.css'
import { toastr } from 'react-redux-toastr'
import { isNull } from '../../utils/util'

class Cards extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoadingTable: true,
            card: {},
            cardNumber: '',
            isOpenModalDeleted: false,
            isLoadingModal: false,
        }
        this.addNewCard = this.addNewCard.bind(this)

        this.openModalDeleted = this.openModalDeleted.bind(this)
        this.closeModalDeleted = this.closeModalDeleted.bind(this)
        this.confirm = this.confirm.bind(this)
    }

    componentDidMount() {
        this.props.getAllCards()
            .then((_) => {
                this.setState({ isLoadingTable: false })
            })
    }

    openModalDeleted(card) {
        this.setState({ isOpenModalDeleted: true, card: card })
    }

    closeModalDeleted() {
        this.setState({ isOpenModalDeleted: false })
    }

    confirm() {
        const { card } = this.state
        const newStatus = card.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'

        this.setState({ isLoadingModal: true })
        this.props.updateStatus(card.id, newStatus)
        .then((_) => {
            toastr.success('Sucesso', 'Operação realizada com sucesso!')
            this.setState({ isLoadingModal: false })
            this.closeModalDeleted()
        })
        .catch((_) => {
            toastr.error('Erro', 'Ocorreu um erro')
            this.setState({ isLoadingModal: false })
        })        
    }


    addNewCard() {
        const { cardNumber } = this.state

        if (isNull(cardNumber)) {
            toastr.error('Erro', 'Informe o RFID do cartão')
        } else {
            this.props.createCard(cardNumber)
                .then((_) => {
                    toastr.success('Sucesso', 'Operação realizada com sucesso!')
                })
                .catch((_) => {
                    toastr.error('Erro', 'Ocorreu um erro')
                })

        }
    }

    onChangeCardNumber(value) {
        this.setState({ cardNumber: value })
    }

    renderRows() {
        return this.props.cards.map(data => {
            return <tr key={data.id}>
                <th scope="row">{data.id}</th>
                <td>{data.number}</td>
                <td>{data.rfId}</td>
                <td>
                    {
                        data.status === 'ACTIVE' ?
                            <span className="badge badge-success">Ativo</span>
                            : <span className="badge badge-danger">Inativo</span>
                    }

                </td>
                <td>
                    {
                        data.status === 'ACTIVE' ?
                            <button onClick={() => this.openModalDeleted(data)} type="button" className="btn btn-outline-danger buttons-action">
                                Bloquear
                            </button> :
                            <button onClick={() => this.openModalDeleted(data)} type="button" className="btn btn-outline-success buttons-action">
                                Ativar
                            </button>
                    }
                </td>
            </tr>
        })
    }

    renderAddCard() {
        return (
            <div className='add-card'>
                <div className='row'>
                    <label className='label-add-card'>RFID do cartão</label>
                </div>
                <div className='row'>

                    <div className="input-group mb-3 col-md-4">
                        <input onChange={(e) => this.onChangeCardNumber(e.target.value)} type="number" className="form-control" placeholder="RFID do cartão" />
                        <div className="input-group-append">
                            <button onClick={this.addNewCard} className="btn btn-outline-secondary" type="button">Adicionar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    render() {

        const { isOpenModalDeleted, card, isLoadingModal, isLoadingTable } = this.state

        return (
            <div>
                <div className='row'>
                    <ContentHeader title='Cartões' />
                </div>

                <CustomModal
                        title={card.status === 'ACTIVE' ? 'Bloquear Cartão' : 'Ativar Cartão'}
                        subtitle={`Deseja ${card.status === 'ACTIVE' ? 'Bloquear' : 'Ativar'} o cartão ${card.rfId} ?`}
                        isOpen={isOpenModalDeleted}
                        isLoading={isLoadingModal}
                        confirm={this.confirm}
                        close={this.closeModalDeleted} />

                {this.renderAddCard()}

                {
                    !isLoadingTable ? <div className="card">
                        <div className="card-body">


                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Número</th>
                                        <th scope="col">RFID</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRows()}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className="card">
                        <TableShimmer />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({ cards: state.cards.cards })
const mapDispatchToProps = dispatch => bindActionCreators({ getAllCards, createCard, updateStatus }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Cards)
