import {BarrelActionType} from './barrelsActionTypes'

const INITIAL_STATE = {barrels: [], volumes: [],
    barrel: {
        name: null,
        price: null,
        productDescription: null,
        alcoholContent: null,
        bitterness: null,
        color: null,
        cup: null,
        family: null,
        style: null,
        temperature: null,
        srm: null,
        school: null,
        barrelVolumeId: null
    }
}


export const BarrelsReducer = function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BarrelActionType.LIST_ALL:
            return { ...state, barrels: action.payload }
        case BarrelActionType.LIST_ALL_VOLUMES:
            return { ...state, volumes: action.payload }
        default:
            return state
    }
}