import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ContentHeader } from '../../../components/contentHeader'
import { useParams } from 'react-router'
import { CardForm } from '../../../components/cards/CardForm'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { validate } from './transactionValidate'
import { LabelAndInput } from '../../../components/inputs/labelAndInput'
import { CustomCurrencyField } from '../../../components/inputs/CustomCurrencyField'
import { toasterError, toasterSuccess } from '../../../shared/notifications/customToaster'
import { formatCurrencyInputBR, formatReal } from '../../../utils/util'
import CustomModal from '../../../components/modals/modal'
import { PhoneInput } from '../../../components/inputs/PhoneInput'
import { TransactionType } from '../models/TransactionType'
import {
    getTransactionsByRfid, getBalanceByRfid,
    addBalance,
    startForm,
    selectType,
    updateStatusByRfid,
    resetCard,
    resetForm,
    resetBalance,
    selectCardType
} from '../cardAction'
import { CardType } from '../models/CardType';
import { TransactionsTable } from '../components/TransactionsTable';
import '../cards.css'


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class CardTransactions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoadingTable: true,
            isLoadingBalance: true,
            isLinkCard: false,
            isLoadingButton: false,
            isPrepaid: true,
            isLoadingModal: false,
            isOpenModal: false,
            currentTransactionType: TransactionType.INPUT,
            cardType: CardType.PREPAID,
            modal: { title: '', subtitle: '', action: '' },
        }

        this.persist = this.persist.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.confirm = this.confirm.bind(this)
    }

    componentDidMount() {
        resetForm()
        this.props.resetBalance()
        this.init()
    }

    getTransactions() {
        this.props.getTransactionsByRfid(this.props.params.rfid, 0, 10)
            .then((_) => {
                this.setState({ isLoadingTable: false })
            })
    }

    init() {
        this.getTransactions();
        this.setTransactionTypeDefault();
        this.props.getBalanceByRfid(this.props.params.rfid)
            .then((resp) => {
                const body = resp.payload;
                this.setState({ isLoadingBalance: false, isPrepaid: body.cardType === CardType.PREPAID, cardType: body.cardType })
                const data = {
                    userDocument: this.props.balance.userDocument,
                    type: TransactionType.INPUT,
                    consumerName: body.consumerName,
                    consumerPhone: body.consumerPhone,
                    cardType: body.cardType
                }
                this.props.startForm(data)
            }).catch(e => {
                this.setTransactionTypeDefault();
                if (e.response.data instanceof Array) {
                    const error = e.response.data[0]
                    if (error.code === 'CARD-3') {
                        this.setState({ isLoadingBalance: false, isLinkCard: true })
                    } else {
                        this.setState({ isLoadingBalance: false })
                    }
                }
            });

    }

    setTransactionTypeDefault() {
        const body = {
            type: TransactionType.INPUT,
            cardType: CardType.PREPAID
        }
        this.props.startForm(body)
        this.setState({ currentTransactionType: TransactionType.INPUT, isRequiredReason: false })
    }

    handlePageClick = (event) => {
        this.setState({ isLoadingTable: true })
        this.props.getTransactionsByRfid(this.props.params.rfid, event.selected, 10)
            .then((_) => {
                this.setState({ isLoadingTable: false })
            })
    };

    persist(values) {
        this.props.validate(this.props.formValues)
        const data = {
            userDocument: values.userDocument,
            consumerName: values.consumerName,
            consumerPhone: values.consumerPhone,
            cardType: values.cardType,
            amount: parseFloat(formatCurrencyInputBR(values.amount)),
            type: values.cardType === CardType.PREPAID ? values.type : TransactionType.INPUT,
            reason: values.reason
        }
        this.setState({ isLoadingButton: true })
        this.props.addBalance(this.props.params.rfid, data)
            .then((_) => {
                this.setTransactionTypeDefault();
                toasterSuccess()
                this.init()
                this.setState({ isLoadingButton: false, isLinkCard: false })
            }).catch((_) => {
                this.setState({ isLoadingButton: false })
                toasterError();
            })
    }

    changeType(type) {
        this.setState({ currentTransactionType: type })
        if (type === TransactionType.OUTPUT) {
            this.setState({ isRequiredReason: true })
        } else {
            this.setState({ isRequiredReason: false })
        }

        this.props.selectType(type, this.props.formValues)
        this.props.validate(this.props.formValues)
    }

    changeCardType(type) {
        this.setState({ cardType: type })
        if (type === CardType.POSTPAID) {
            this.setState({ isPrepaid: false })
        } else {
            this.setState({ isPrepaid: true })
        }

        this.props.selectCardType(type, this.props.formValues)
        this.props.validate(this.props.formValues)
    }

    closeModal() {
        this.setState({ isOpenModal: false })
    }

    openModal(action, barrel) {
        let title = ''
        let subtitle = ''

        if (action === 'RESET') {
            title = 'Resetar dados do cartão'
            subtitle = `Todas as informações como movimentações e saldo serão perdidas, deseja continuar?`
        } else {
            title = 'Bloquear cartão'
            subtitle = `Essa ação ira impedir o uso do cartão, deseja continuar?`
        }

        this.setState({
            isOpenModal: true,
            barrelSelected: barrel,
            modal: {
                title: title,
                action: action,
                subtitle: subtitle
            }
        })
    }

    confirm() {
        const { modal } = this.state
        this.setState({ isLoadingModal: true })


        if (modal.action === 'BLOCK') {
            const status = this.props.balance.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
            this.props.updateStatusByRfid(this.props.params.rfid, status)
                .then((_) => {
                    toasterSuccess()
                    this.setState({ isLoadingModal: false })
                    this.closeModal()
                }).catch((_) => {
                    this.setState({ isLoadingModal: false })
                    toasterError();
                    this.closeModal()
                })
        } else {
            this.props.resetCard(this.props.params.rfid)
                .then((_) => {
                    toasterSuccess()
                    this.setState({
                        isLoadingModal: false,
                        isLinkCard: true,
                        isPrepaid: true,
                        currentTransactionType: TransactionType.INPUT,
                        cardType: CardType.PREPAID,
                    })
                    this.closeModal()
                    this.getTransactions();

                    const body = {
                        type: TransactionType.INPUT,
                        cardType: CardType.PREPAID
                    }
                    this.props.startForm(body)
                }).catch((_) => {
                    this.setState({ isLoadingModal: false })
                    toasterError();
                    this.closeModal()
                })
        }
    }


    render() {
        const { isLoadingTable, isLoadingBalance, isLinkCard, isLoadingButton,
            isRequiredReason, modal, isOpenModal, isLoadingModal,
            currentTransactionType, cardType, isPrepaid } = this.state
        return (
            <div>
                <div className='row'>
                    <ContentHeader title='Detalhes do cartão' />
                </div>
                <CustomModal
                    title={modal.title}
                    subtitle={modal.subtitle}
                    isOpen={isOpenModal}
                    isLoading={isLoadingModal}
                    confirm={this.confirm}
                    close={this.closeModal} />

                {
                    isLoadingBalance ? false : <div className='row'>
                        <div className="col-12">

                            <div className={`small-box ${this.props.balance.status === 'ACTIVE' ? 'bg-success' : 'bg-danger'}`}>
                                <div className="inner">
                                    <h3>{formatReal(this.props.balance.total, {})}</h3>
                                    <p>Saldo - {this.props.balance.status === 'ACTIVE' ? 'Cartão ativo' : 'Cartão bloqueado'} </p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-money-check-alt"></i>
                                </div>
                                <a data-toggle="collapse" href={`#cardBalance`} className="small-box-footer">
                                    <i className="fas fa-solid fa-chevron-down"></i>
                                </a>
                                <div className="collapse" id={`cardBalance`}>
                                    <div className='bt-actions'>
                                        <button type="button" className="btn btn-outline-primary" onClick={() => this.openModal('RESET')}>
                                            Resetar Cartão
                                        </button>
                                        <button type="button" className="btn btn-outline-danger bt-actions-trash" onClick={() => this.openModal('BLOCK')}>
                                            {this.props.balance.status === 'ACTIVE' ? 'Bloquear Cartão' : 'Desbloquear Cartão'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {isLinkCard ? <div className="alert alert-warning col-md-12" role="alert">
                            Cartão não possui nenhum usuário associado.
                        </div> : false}
                    </div>
                }

                {
                    isLoadingBalance ? false : <div className='row'>
                        <div className='col-md-12'>
                            <CardForm isLoading={isLoadingButton} handleSubmit={this.props.handleSubmit}
                                click={this.persist}
                                title={isLinkCard ? 'Vincular cartão' : 'Adicionar saldo'}>
                                <div className='form-link-card'>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Tipo do cartão</label><br></br>
                                            <div className="btn-group btn-group-toggle btns-actions-register col-md-12" data-toggle="buttons">
                                                <button onClick={() => this.changeCardType(CardType.PREPAID)}
                                                    type="button" className={cardType === CardType.PREPAID ? 'btn btn-dark' : 'btn btn-outline-dark'}>
                                                    Pré-Pago
                                                </button>

                                                <button onClick={() => this.changeCardType(CardType.POSTPAID)}
                                                    className={cardType !== CardType.PREPAID ? 'btn btn-dark' : 'btn btn-outline-dark'}
                                                    type="button">
                                                    Pós-Pago
                                                </button>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            {isPrepaid ? <div style={{ marginTop: '25px' }} className="alert alert-info" role="alert">
                                                Pré-pago: Pague antes, use depois.
                                            </div> : <div style={{ marginTop: '25px' }} className="alert alert-info" role="alert">
                                                Pós-pago: Use primeiro, pague depois.
                                            </div>}
                                        </div>
                                    </div>

                                    {isPrepaid &&
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Tipo de transação</label><br></br>
                                                <div className="btn-group btn-group-toggle btns-actions-register col-md-12" data-toggle="buttons">
                                                    <button onClick={() => this.changeType(TransactionType.INPUT)}
                                                        type="button" className={currentTransactionType === TransactionType.INPUT ? 'btn btn-dark' : 'btn btn-outline-dark'}>
                                                        Registrar entrada
                                                    </button>

                                                    <button onClick={() => this.changeType(TransactionType.OUTPUT)}
                                                        className={currentTransactionType !== TransactionType.INPUT ? 'btn btn-dark' : 'btn btn-outline-dark'}
                                                        type="button">
                                                        Registrar saida
                                                    </button>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                {isRequiredReason ? <Field name='reason' component={LabelAndInput} isRequired={isRequiredReason}
                                                    label='Motivo' placeholder='motivo da transação' /> : false}
                                            </div>
                                        </div>
                                    }


                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field name='userDocument' component={LabelAndInput} isRequired
                                                label='CPF' placeholder='CPF' readOnly={!isLinkCard} />
                                        </div>
                                        <div className="col-md-6">
                                            <Field name='amount' autoFocus={true} component={CustomCurrencyField} isRequired type='number'
                                                label='Valor' placeholder='valor' />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field name='consumerName' component={LabelAndInput} isRequired
                                                label='Nome' placeholder='nome' readOnly={!isLinkCard} />
                                        </div>
                                        {<div className="col-md-6">
                                            <Field name='consumerPhone' autoFocus={true} component={PhoneInput} isRequired
                                                label='Telefone' placeholder='telefone' readOnly={!isLinkCard} />
                                        </div>}
                                    </div>
                                </div>
                            </CardForm>
                        </div>

                        <div className='col'>
                            <TransactionsTable
                                transactions={this.props.transactions}
                                isLoadingTable={isLoadingTable}
                                handlePageClick={this.handlePageClick} />
                        </div>
                    </div>
                }

            </div>
        )
    }
}

CardTransactions = reduxForm({
    form: 'transactionForm',
    destroyOnUnmount: false,
    validate: validate,
})(CardTransactions)


const mapStateToProps = state => (
    {
        transactions: state.cards.transactions,
        balance: state.cards.balance,
        formValues: getFormValues('transactionForm')(state)
    })
const mapDispatchToProps = dispatch => bindActionCreators({
    getTransactionsByRfid,
    getBalanceByRfid,
    addBalance,
    startForm,
    selectType,
    updateStatusByRfid,
    resetCard,
    resetBalance,
    selectCardType
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withParams(CardTransactions))
