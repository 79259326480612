import {CompaniesActionTypes} from './companiesActionTypes'

const INITIAL_STATE = {companies: [], 
    company: { 
        name: '', 
        companyName: '', 
        document: '',
        zipcode: '', 
        number: '', 
        complement: '',        
    },
    address: {
        street: '',
        district: '',
        state: '',
        city: ''
    }
}

export const CompanyReducer = function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CompaniesActionTypes.LIST_ALL_COMPANIES:
            return { ...state, companies: action.payload }
        case CompaniesActionTypes.ADDRESS_VIA_ZIPCODE:
            return { ...state, address: action.payload }
        case CompaniesActionTypes.CHANGE_FORM:
             return { ...state, address: action.payload.address, company: action.payload }
        default:
            return state
    }
}