import React from 'react'
import {Shimmer} from '../loaders/shimmer'

export const TableShimmer = () => (
    <table className="table table-striped">
        <thead>
            <tr>
                <th scope="col"><Shimmer /></th>
                <th scope="col"><Shimmer /></th>
                <th scope="col"><Shimmer /></th>
                <th scope="col"><Shimmer /></th>
                <th scope="col"><Shimmer /></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
            </tr>
            <tr>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
            </tr>
            <tr>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
                <td><Shimmer /></td>
            </tr>

        </tbody>
    </table>
)