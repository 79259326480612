import {DevicesActionType} from './devicesActionTypes'

const INITIAL_STATE = {devices: []}


export const DeviceReducer = function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case DevicesActionType.LIST_ALL:
            return { ...state, devices: action.payload }
        default:
            return state
    }
}