import jwt_decode from "jwt-decode";
import { usersMock } from '../../utils/mockUser'

export function changeCurrentUser(email) {
    const token = usersMock(email)
    const decoded = jwt_decode(token);
    localStorage.setItem('accessToken', token)
    const data = {
        'email': decoded.email,
        'name': decoded.fullName,
        'isFullAdmin': decoded.role.includes('ROLE_ADMIN')
    }
    return {
        type: 'CHANGE_USER',
        payload: data
    }
}


export function getUserData() {
    const token = sessionStorage.getItem('accessToken')
    const decoded = jwt_decode(token);
    return {
        isFullAdmin: decoded.role?.includes('ROLE_ADMIN'),
        fullName: decoded.fullName,
        email: decoded.email,
        companyId: decoded.companies,
        rules: decoded.role
    }
}