import { get } from '../../utils/httpCustom'
import {DashboardActionTypes} from './dashboardActionTypes'

function uriDashboard() {
    return '/v1/dashboards'
}

export function getSummary() {
    return get(uriDashboard().concat('/summary'))
        .then(resp => {
            return {
                type: DashboardActionTypes.SUMMARY,
                payload: resp.data
            }
        }).catch((_) => {
            return {
                type: DashboardActionTypes.SUMMARY,
                payload: {}
            }
        })
}

export function getProductsRanking() {
    return get(uriDashboard().concat('/products/ranking'))
        .then(resp => {
            return resp.data
        }).catch((_) => {
            return {}
        })
}


export function getVisitsRanking() {
    return get(uriDashboard().concat('/visits/ranking'))
        .then(resp => {
            return resp.data
        }).catch((_) => {
            return {}
        })
}
