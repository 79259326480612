import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { createBarrel, getAllVolumes, changeForm, resetForm, findById, update } from '../barrelAction'
import { ContentHeader } from '../../../components/contentHeader'
import { CardForm } from '../../../components/cards/CardForm'
import { reduxForm, Field, getFormValues } from 'redux-form'
import { LabelAndInput } from '../../../components/inputs/labelAndInput'
import { validate } from './barrelValidate'
import { CustomCurrencyField } from '../../../components/inputs/CustomCurrencyField'
import { SelectFieldInput } from '../../../components/inputs/SelectField'
import { formatCurrencyInputBR, formatDateToUS, isNull } from '../../../utils/util'
import { useParams } from 'react-router'
import Spinner from '../../../shared/spinners/spinner'
import { toasterError, toasterSuccess } from '../../../shared/notifications/customToaster'
import {CustomDatePiker} from '../../../components/calendar/CustomDatePiker'
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


class Barrel extends Component {

    componentDidMount() {
        this.props.getAllVolumes()
            .then((_) => {
                const options = this.props.volumes.map((data) => {
                    const op = {
                        value: data.id,
                        label: `${data.liters} Litros`
                    }
                    return op
                })

                this.setState({ options: options })
            });

        const { isEdit } = this.state
        if (isEdit) {
            this.setState({ isLoadingPage: true })
            this.props.findById(this.props.params.id)
                .then((_) => {
                    this.setState({ isLoadingPage: false })
                })
        } else {
            this.props.changeForm({})
        }
    }

    constructor(props) {
        super(props)
        this.persist = this.persist.bind(this)
        this.changeDate = this.changeDate.bind(this)

        this.state = {
            isLoading: false,
            isLoadingPage: false,
            options: [],
            volume: {},
            isEdit: this.props.params.id != null
        }
    }

    parseNumberFromString(input) {
        var cleanString = input.replace(/[^\d.,]/g, ''); // Remove caracteres não numéricos
        var cleanNumber = parseFloat(cleanString.replace(",", ".")); // Substitui ',' por '.' e converte para número
        return cleanNumber;
    }

    persist(values) {
        const data = {
            name: values.name,
            price: parseFloat(formatCurrencyInputBR(values.price)),
            productDescription: values.productDescription,
            alcoholContent: parseFloat(values.alcoholContent),
            bitterness: isNull(values.bitterness) ? null : +values.bitterness,
            color: values.color,
            cup: values.cup,
            family: values.family,
            style: values.style,
            temperature: isNull(values.temperature) ? null : +values.temperature,
            srm: isNull(values.srm) ? null : +values.srm,
            school: values.school,
            barrelVolumeId: +values.barrelVolumeId,
            dueDate: isNull(values.dueDate) ? null : formatDateToUS(values.dueDate),
            costValue: isNull(values.costValue) ? null : parseFloat(formatCurrencyInputBR(values.costValue))
        }

        this.setState({ isLoading: true })

        const { isEdit } = this.state

        if (isEdit) {
            update(this.props.params.id, data)
                .then((_) => {
                    toasterSuccess()
                    this.setState({ isLoading: false })
                }).catch((_) => {
                    this.setState({ isLoading: false })
                    toasterError();
                })
        } else {
            this.props.createBarrel(data)
                .then((_) => {
                    toasterSuccess()
                    this.setState({ isLoading: false })
                    this.props.resetForm()
                }).catch((_) => {
                    this.setState({ isLoading: false })
                    toasterError();
                })
        }
    }

    changeDate(dt) {
        const date = dayjs(dt).format("DD/MM/YYYY")
        if (isNull(this.props.formValues)) {
            this.props.formValues = { dueDate: date }
        } else {
            this.props.formValues.dueDate = date
        }
        this.props.changeForm(this.props.formValues)
    }

    renderRowsTaps() {
        return this.props.formValues.taps.map(tap => {
            return <tr key={tap.id}>
                <td>{tap.id}</td>
                <td>
                    <Link to={`/taps/device/${tap.deviceId}/tap/${tap.id}`}>
                        {tap.tag || tap.id}
                    </Link>
                </td>
                <td>{tap.deviceTag}</td>
            </tr>
        })
    }

    render() {
        const { options, isLoading, isEdit, isLoadingPage } = this.state
        return (
            <div>
                <div className='row'>
                    <ContentHeader title={isEdit ? 'Atualizar Barril' : 'Cadastrar Barril'} />
                </div>

                {
                    isLoadingPage ? <div className='row spinner-center'><Spinner /></div>
                        : <>
                            <div className="alert alert-warning" role="alert">
                                Os campos com <span className='field-required'>*</span> são obrigatórios.
                            </div>


                            <CardForm title={isEdit ? 'Atualizar Barril' : 'Cadastrar Barril'} click={this.persist} isLoading={isLoading}
                                handleSubmit={this.props.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Field name='name' component={LabelAndInput} isRequired
                                            label='Identificação do barril' placeholder='exemplo: 01-Barril'
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Field name='price' component={CustomCurrencyField} isRequired
                                            label='Preço por litro' placeholder='informe o valor por ML' />

                                    </div>

                                    <div className="col-md-4">
                                        <Field name='productDescription' component={LabelAndInput} isRequired
                                            label='Nome do produto' placeholder='exemplo: IPA/PILSEN/...' />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <Field name='alcoholContent' component={LabelAndInput} type='number' isRequired
                                            label='Teor alcoólico' placeholder='valor em porcentagem do teor alcoólico'
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Field name='bitterness' component={LabelAndInput} isRequired type='number'
                                            label='Amargura' placeholder='valor em porcentagem da amargura' />
                                    </div>

                                    <div className="col-md-4">
                                        <Field name='color' component={LabelAndInput}
                                            label='Coloração' placeholder='coloração da bebida' />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <Field name='cup' component={LabelAndInput}
                                            label='Copo' placeholder='copo'
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Field name='family' component={LabelAndInput} isRequired
                                            label='Familia' placeholder='familia' />
                                    </div>

                                    <div className="col-md-4">
                                        <Field name='school' component={LabelAndInput}
                                            label='Escola' placeholder='escola' />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <Field name='style' component={LabelAndInput}
                                            label='Estilo' placeholder='estilo'
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Field name='temperature' component={LabelAndInput} type='number'
                                            label='Temperatura' placeholder='temperatura' />
                                    </div>

                                    <div className="col-md-4">
                                        <Field name='srm' component={LabelAndInput} type='number'
                                            label='Srm' placeholder='srm' />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Field name='barrelVolumeId'
                                            readOnly={isEdit}
                                            component={SelectFieldInput}
                                            isRequired
                                            options={options}
                                            label='Volume'
                                            placeholder='selecione um volume'
                                        >
                                            <option value="">Selecione um volume</option>
                                            {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}

                                        </Field>
                                    </div>
                                    <div className="col-md-4">
                                        <Field name='dueDate'
                                            isRequired
                                            component={CustomDatePiker}
                                            changeDate={this.changeDate}
                                            minDate={new Date()}
                                            label='Data de vencimento do barril'
                                            placeholder='data de vencimento do barril' />
                                    </div>

                                    <div className="col-md-4">
                                        <Field name='costValue' component={CustomCurrencyField}
                                            label='Valor de custo por litro' placeholder='informe o valor de custo' />
                                    </div>
                                </div>
                            </CardForm>

                            {
                                isEdit && !isNull(this.props.formValues)
                                    && !isNull(this.props.formValues.taps)
                                    && this.props.formValues.taps.length > 0 ?
                                    <div className='row'>
                                        <div className="card col-md-12">
                                            <div className="card-header">
                                                <h3 className="card-title">Torneiras vinculadas a esse barril</h3>
                                            </div>

                                            <div className="card-body p-0">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Torneira - Tag</th>
                                                            <th>Monitor - Tag</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.renderRowsTaps()}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div> : false
                            }
                        </>
                }

            </div>
        )
    }
}

Barrel = reduxForm({
    form: 'barrelForm',
    validate: validate,
    destroyOnUnmount: false,
})(Barrel)

const mapStateToProps = state => ({
    volumes: state.barrels.volumes,
    formValues: getFormValues('barrelForm')(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({
    getAllVolumes, createBarrel, changeForm, resetForm, findById
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withParams(Barrel))