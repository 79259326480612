import {CardActionType} from './cardsActionTypes'

const INITIAL_STATE = {cards: [], transactions: [], balance: {total: 0, userDocument: '', status: '', cardType: ''}, currentCard: null }


export const CardReducer = function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CardActionType.LIST_ALL_CARDS:
            return { ...state, cards: action.payload }
        case CardActionType.LIST_TRANSACTIONS:
            return { ...state, transactions: action.payload }
        case CardActionType.CARD_BALANCE:
            return { ...state, balance: action.payload }
        case CardActionType.CURRENT_CARD:
            return { ...state, currentCard: action.payload }
        default:
            return state
    }
}