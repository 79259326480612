import React from 'react'


export const CardSummary = props => {
    return (
        <div className="col-lg-3 col-6">
            <div className={`small-box ${props.className}`}>
                <div className="inner">
                    <h3>{props.title}</h3>
                    <p>{props.subtitle}</p>
                </div>
                <div className="icon">
                <i className={`fas ${props.icon}`}></i>
                </div>
            </div>
        </div>
    )
}