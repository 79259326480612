import { combineReducers } from 'redux'
import {UserReducer} from './shared/users/userReducer'
import {CompanyReducer} from './pages/companies/companyReducer'
import { reducer as formReducer } from 'redux-form'
import {reducer as toastrReducer} from 'react-redux-toastr'
import {CardReducer} from './pages/cards/cardReducer'
import {DeviceReducer} from './pages/devices/deviceReducer'
import {BarrelsReducer} from './pages/barrels/barrelReducer'
import {TapReducer} from './pages/taps/tapReducer'
import {DashboardReducer} from './pages/dashboard/dashboardReducer'


const rootReducer = combineReducers({
    form: formReducer,
    users: UserReducer,
    companies: CompanyReducer,
    toastr: toastrReducer,
    cards: CardReducer,
    devices: DeviceReducer,
    barrels: BarrelsReducer,
    taps: TapReducer,
    dashboards: DashboardReducer
})

export default rootReducer