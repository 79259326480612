export const CardType = {
    PREPAID: 'PREPAID',
    POSTPAID: 'POSTPAID',
  };
  

  export const getCardTypeLabel = (cardType) => {
    switch (cardType) {
      case CardType.PREPAID:
        return 'Pré-pago';
      case CardType.POSTPAID:
        return 'Pós-pago';
      default:
        return 'Tipo de cartão desconhecido';
    }
  };