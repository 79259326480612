import { isNull } from '../../../utils/util'

export function validate (values) {
    const errors = {}
    if (isNull(values.email)) {
        errors.email = 'Campo obrigatório'
    }

    if (isNull(values.password)) {
        errors.password = 'Campo obrigatório'
    }

    if (isNull(values.fullName)) {
        errors.fullName = 'Campo obrigatório'
    }

    return errors
}