import React from 'react';
import { formatPhone, formatReal } from '../../../utils/util';
import { Link } from 'react-router-dom';
import logoImg from '../../../assets/images/logo.png'
import { getCardTypeLabel } from '../models/CardType';
import dayjs from 'dayjs';


export const CardComponent = ({card}) => {
    return (
            <Link to={'/card/'+card?.rfid}>
                <div className="small-box bg-dark">
                    <div className="inner">
                        <h3>{formatReal(card?.total)}</h3>
                        <p>{getCardTypeLabel(card?.cardType)}</p>
                        <p>Associado em: {dayjs(card.createDate).format("DD/MM/YYYY HH:mm")}</p>
                        <p>{card?.consumerName} - {formatPhone(card?.consumerPhone)}</p>
                    </div>
                    <div className="icon">
                        <i className="fas">
                            <img className='img-logo-card' src={logoImg} alt='card'></img>
                        </i>
                    </div>
                </div>
            </Link>
    );
};