import React from 'react'
import './cards.css'

export const CustomCard = props => (
    <div className="card card-primary">
        <div className="card-header">
            <h3 className="card-title">{props.title}</h3>
        </div>
        <div className="card-body">
            {props.children}
        </div>
    </div>
)