import React, { Component, useContext } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeCurrentUser } from '../shared/users/userAction'
import { AuthContext } from './contexts/auth'


export const HeaderContext = () => {
  const { signOut } = useContext(AuthContext);

  async function logout(email, password) {
    await signOut();
  }

  return <Header handleLogout={logout}/>
};

class Header extends Component {

  logout() {
    this.props.handleLogout()
  }

  render() {

    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="www.google.com.br" role="button"><i className="fas fa-bars"></i></a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <button type="button" className="btn btn-link">Dashboard</button>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <button type="button" className="btn btn-link">Contato</button>
          </li>
          <li>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <button onClick={() => this.logout()} type="button" className="btn btn-link">
              <i className="fas fa-sign-out-alt"></i>
            </button>
          </li>
        </ul>
      </nav>
    )
  }
}

const mapStateToProps = state => ({ user: state.users.currentUser })
const mapDispatchToProps = dispatch => bindActionCreators({ changeCurrentUser }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Header)
