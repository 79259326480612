import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuItem = (props) => (
  <li className="nav-item">
    <NavLink to={props.path} className='nav-link'>
      <i className={`nav-icon fas fa-${props.icon}`}></i> <span>{props.label}</span>
    </NavLink>
  </li>
)
export default MenuItem;