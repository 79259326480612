import React from 'react'

import { Route, Routes } from 'react-router'
import Companies from './pages/companies/companies'
import Company from './pages/companies/pages/company'
import Cards from './pages/cards/cards'
import Devices from './pages/devices/devices'
import Barrels from './pages/barrels/barrels'
import Barrel from './pages/barrels/pages/barrel'
import Taps from './pages/taps/taps'
import Tap from './pages/taps/pages/tap'
import CardBalance from './pages/cards/pages/cardBalance'
import CardTransactions from './pages/cards/pages/cardTransactions'
import BarrelReplace from './pages/barrels/pages/barrelReplace'
import { DashboardByUser } from './pages/dashboard/dashboard'
import { getUserData } from './shared/users/userAction'
import DashboardEmployee from './pages/dashboard/dashboard'
import { CardsPage } from './pages/cards/pages/CardsPage'

export const RoutesApp = () => (
    <div className='content-wrapper content'>
        <Routes>
            <Route path="/" element={getUserData().isFullAdmin ? <DashboardByUser /> : <DashboardEmployee />} />

            <Route path="companies" element={<Companies />} />
            <Route path="companies/company" element={<Company />} />
            <Route path="companies/company/:id"  element={<Company />} /> 

            <Route path="cards"  element={getUserData().isFullAdmin ? <Cards /> : <CardsPage/>} /> 
            <Route path="card"  element={<CardBalance />} /> 
            <Route path="card/:rfid"  element={<CardTransactions />} /> 

            <Route path="devices"  element={<Devices />} /> 

            <Route path="barrels"  element={<Barrels />} /> 
            <Route path="barrels/barrel"  element={<Barrel />} /> 
            <Route path="barrels/:id"  element={<Barrel />} /> 
            <Route path="barrels/:id/replace"  element={<BarrelReplace />} /> 


            <Route path="taps"  element={<Taps />} /> 
            <Route path="taps/device/:deviceId"  element={<Tap/>} /> 
            <Route path="taps/device/:deviceId/tap/:tapId"  element={<Tap/>} /> 
        </Routes>
    </div>
)

