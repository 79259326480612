import React from 'react'
import IntlCurrencyInput from "react-intl-currency-input"

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};


export const CustomCurrencyField = ({ input, label, isRequired, name, meta: { touched, error, warning } }) => (
    <div className='form-group'>
        <label htmlFor={name}>{label} {isRequired ? <span className='field-required'>*</span> : false}</label>
        <IntlCurrencyInput className={`form-control ${touched && error ? 'is-invalid' : ''}`} {...input} currency="BRL" config={currencyConfig} />
        <div className="invalid-feedback">
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    </div>

)