import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {ContentHeader} from '../../../components/contentHeader'
import {CardBarrel} from '../components/CardBarrel'
import { replace, getAllBarrels } from '../barrelAction'
import { useNavigate, useParams } from 'react-router'
import CustomModal from '../../../components/modals/modal'
import { toasterError, toasterSuccess } from '../../../shared/notifications/customToaster'
import Spinner from '../../../shared/spinners/spinner'


function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}


class BarrelReplace extends Component {

    constructor(props) {
        super(props)

        this.renderBarrelsFree = this.renderBarrelsFree.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.confirm = this.confirm.bind(this)

        this.state = {
            isLoading: true,
            isLoadingModal: false,
            isOpenModal: false,
            barrelSelected: {},
            barrel: {},
            barrelsFree: []
        }

    }

    componentDidMount() {
        this.props.getAllBarrels()
            .then((_) => {
                const currentBarrel = this.props.barrels.filter(barrel => barrel.id === +this.props.params.id)[0]
                this.setState({ barrel: currentBarrel, barrelsFree: this.getBarrelsWithOutTaps(), isLoading: false })
            })
    }

    getBarrelsWithOutTaps() {
        return this.props.barrels.filter(barrel => (barrel.id !== +this.props.params.id))
    }

    openModal(barrel) {
        this.setState({ isOpenModal: true, barrelSelected: barrel })
    }

    closeModal() {
        this.setState({ isOpenModal: false })
    }


    confirm() {
        const { barrel, barrelSelected } = this.state
        this.setState({ isLoadingModal: true })
        this.props.replace(barrel.id, barrelSelected.id)
            .then((_) => {
                toasterSuccess()
                this.setState({ isLoadingModal: false })
                this.props.navigate(`/barrels`)
            }).catch((_) => {
                this.setState({ isLoadingModal: false })
                toasterError();
            })
    }



    renderBarrelsFree() {
        const { barrelsFree } = this.state
        return barrelsFree.map(barrel => {
            return <Fragment key={barrel.id}>
                <CardBarrel
                    selected={this.openModal}
                    barrel={barrel}
                    hideActions={true}
                    openModal={this.openModal}
                    toBarrelReplace={this.toBarrelReplace}
                    percentage={barrel.percentageLiters} />
            </Fragment>
        })
    }

    changeValueSearchBarrel(value) {
        const filterList = this.getBarrelsWithOutTaps().filter(barrel => barrel.name.toLowerCase()
        .includes(value.toLowerCase())
        || barrel.productDescription.toLowerCase().includes(value.toLowerCase()))

        this.setState({ barrelsFree: filterList })

    }

    render() {
        const { barrel, isOpenModal, isLoadingModal, barrelSelected, isLoading } = this.state
        return (
            <>
                <ContentHeader title='Substituir Barril' />

                <CustomModal
                    title={'Substituir barril'}
                    subtitle={`Deseja substituir as torneira do barril (${barrel.name} - ${barrel.productDescription} - 
                        ${barrel.liters}L) para o barril (${barrelSelected.name} - ${barrelSelected.productDescription} - ${barrelSelected.liters}L)?`}
                    isOpen={isOpenModal}
                    isLoading={isLoadingModal}
                    confirm={this.confirm}
                    close={this.closeModal} />

                <>
                    {
                        isLoading ? <div className='row spinner-barrel'>
                            <Spinner />
                        </div> :
                            <div>
                                {
                                    isOpenModal ? false : <div className='row'>
                                        <CardBarrel hideActions={true} barrel={barrel}
                                            openModal={this.openModal}
                                            toBarrelReplace={this.toBarrelReplace}
                                            percentage={barrel.percentageLiters} />
                                    </div>
                                }

                                <hr />
                                <div className='row'>
                                    <div className="form-group col-md-12 container">
                                        <label >Pesquisar barril</label>
                                        <input onChange={(e) => this.changeValueSearchBarrel(e.target.value)} type="text" className="form-control" placeholder="Pesquisar barril" />
                                    </div>
                                </div>
                                <hr />

                                {
                                    isOpenModal ? false : <div className='row'>
                                        {this.renderBarrelsFree()}
                                    </div>
                                }
                            </div>
                    }
                </>



            </>
        )
    }
}

const mapStateToProps = state => ({ barrels: state.barrels.barrels })
const mapDispatchToProps = dispatch => bindActionCreators({ getAllBarrels, replace }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withParams(BarrelReplace))