import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {ContentHeader} from '../../../components/contentHeader'
import CompanyForm from '../components/companyForm'
import { create, findById, resetForm, update } from '../companyAction'
import { useParams } from "react-router-dom";
import Spinner from '../../../shared/spinners/spinner'
import UserCompanyForm from '../components/userCompanyForm'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Company extends Component {

    constructor(props) {
        super(props)
        this.persist = this.persist.bind(this)
        this.state = {
            isLoading: false,
            isLoadingPage: false,
            isEdit: this.props.params.id != null
        }
    }


    componentDidMount() {
        const { isEdit } = this.state
        if (isEdit) {
            this.setState({ isLoadingPage: true })
            this.props.findById(this.props.params.id)
                .then((_) => {
                    this.setState({ isLoadingPage: false })
                })
        } else {
            this.props.resetForm()
        }
    }

    persist(values) {
        const { isEdit } = this.state

        const { address } = this.props

        const data = {
            name: values.name,
            companyName: values.companyName,
            document: values.document,
            nickname: values.nickname,
            address: {
                street: address.street,
                district: address.district,
                state: address.state,
                city: address.city,
                number: values.number,
                zipcode: values.zipcode,
                complement: values.complement
            }
        }
        this.setState({ isLoading: true })

        if (isEdit) {
            this.props.update(this.props.params.id, data).then((_) => {
                this.setState({ isLoading: false })
            }).catch((_) => {
                this.setState({ isLoading: false })
            })

        } else {
            this.props.create(data).then((_) => {
                this.setState({ isLoading: false })
            }).catch((_) => {
                this.setState({ isLoading: false })
            })
        }
    }

    render() {
        const { isLoading, isEdit, isLoadingPage } = this.state

        return (
            <div>
                <ContentHeader title={isEdit ? 'Editar Empresa' : 'Cadastrar Empresa'} />
                {
                    isLoadingPage ? <Spinner /> : <CompanyForm onSubmit={this.persist}
                        isLoading={isLoading} />
                }

                {
                    
                    !isEdit ? null : <div>
                <ContentHeader title='Adicionar Usuário' />

                        <UserCompanyForm companyId={this.props.params.id} onSubmit={this.persist}
                        isLoading={isLoading} />
                    </div>
                }
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    create, findById, resetForm, update
}, dispatch)
const mapStateToProps = state => ({ address: state.companies.address, })

export default connect(mapStateToProps, mapDispatchToProps)(withParams(Company))