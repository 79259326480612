import React from 'react'
import barrelImg from '../../../assets/images/barril.png'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { formatReal, isNull } from '../../../utils/util'

function setStyleCardByPercentage(percentage) {
    if (percentage >= 50) {
        return 'small-box bg-success'
    } else if (percentage >= 30 && percentage <= 49) {
        return 'small-box bg-warning'
    }
    return 'small-box bg-danger'
}

function setProgressClass(percentage) {
    if (percentage >= 50) {
        return 'progress-success'
    } else if (percentage >= 30 && percentage <= 49) {
        return 'progress-warning'
    }
    return 'progress-danger'
}

export const CardBarrel = props => {
    return (
        <div className="col-lg-3 col-6">
            <div onClick={() => isNull(props.selected) ? false : props.selected(props.barrel)} className={setStyleCardByPercentage(props.percentage)}>
                <Link to={props.hideActions ? false : `${props.barrel.id}`}>
                    <div className="inner">
                        <h3>{props.percentage}% </h3>
                        <p>{props.barrel.name} - {props.barrel.productDescription}</p>
                        <span><b>{props.barrel.liters} Litros - R${props.barrel.price}/L</b></span>
                        <p>
                            <span>Dt. Vencimento: {isNull(props.barrel.dueDate) ? 'Sem informação' : dayjs(props.barrel.dueDate).format("DD/MM/YYYY")}</span>
                            <br></br>
                            {isNull(props.barrel.taps) ? '' : `Possui ${props.barrel.taps.length} torneiras`}
                            <br></br>
                            <span>Lucro: <b className={props.barrel.gain < 0 ? 'gain-negative' : false}>{formatReal(props.barrel.gain)}</b></span>
                        </p>

                    </div>

                    <div className="icon">
                        <i className="fas">
                            <img className='img-barrel' src={barrelImg} alt='barril'></img>
                        </i>
                    </div>

                </Link>
                <div className="progress">
                    <div className={setProgressClass(props.percentage)} style={{ width: `${props.percentage}%` }}></div>
                </div>
                <a className="small-box-footer" data-toggle="collapse" href={`#barrel${props.barrel.id}`} >
                    <i className="fas fa-solid fa-chevron-down"></i>
                </a>

                {
                    props.hideActions ? false : <div className="collapse" id={`barrel${props.barrel.id}`}>
                        <div className='bt-actions'>
                            {
                                !isNull(props.barrel.taps) && props.barrel.taps.length > 0 ?
                                    <button type="button" className="btn btn-outline-success" onClick={() => props.toBarrelReplace(props.barrel.id)}>Trocar barril</button>
                                    : false
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}