import React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import el from "date-fns/locale/pt-BR";
registerLocale("br", el);


export const CustomDatePiker = ({ input, minDate, changeDate, label, placeholder, isRequired, meta: { touched, error, warning } }) => (
    <div className='form-group'>
        <label>{label} {isRequired ? <span className='field-required'>*</span> : false}</label>
        <ReactDatePicker
            {...input}
            className={`form-control ${touched && error ? 'is-invalid' : ''}`}
            locale={el}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => changeDate(date)}
            minDate={minDate}
            withPortal
            placeholderText={placeholder}
        />
        <div className="invalid-feedback">
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    </div>

)