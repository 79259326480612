import axios from "axios"
import {APIConst} from "../../consts"

export function login(email, password) {
   const data = {
    'email': email,
    'password': password
   }
   axios.defaults
   .headers
   .common['Authorization'] = null;

   return axios.post(`${APIConst.URL_API}/v1/auth/login`, data)

}
