
import './emptyState.css'

export const EmptyState = () => {
    return (
        <div className="empty-state">
            <h1>Nenhuma Tarefa Encontrada</h1>
            <h5>Parece que não há nenhum conteúdo disponível no momento. 
                Isso pode ser porque você ainda não adicionou nada ou não há informações disponíveis para exibição.
            </h5>
            <i className="fas fa-folder-open folder-empty"></i>
        </div>
    )
};
