import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {ContentHeader} from '../../components/contentHeader'

import { getAllCompanies, deletedById } from './companyAction'

import {TableShimmer} from '../../components/tables/tableShimmer'
import { Link } from 'react-router-dom'

import './companies.css'
import CustomModal from '../../components/modals/modal'
import { toastr } from 'react-redux-toastr'


class Companies extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpenModalDeleted: false,
            isLoadingModal: false,
            company: {}
        }
        this.openModalDeleted = this.openModalDeleted.bind(this)
        this.closeModalDeleted = this.closeModalDeleted.bind(this)
        this.confirm = this.confirm.bind(this)
    }

    componentDidMount() {
        this.props.getAllCompanies()
    }

    openModalDeleted(company) {
        this.setState({ isOpenModalDeleted: true, company: company })
    }

    closeModalDeleted() {
        this.setState({ isOpenModalDeleted: false })
    }

    confirm() {
        const { company } = this.state

        this.setState({ isLoadingModal: true })
        this.props.deletedById(company.id)
        .then((_) => {
            toastr.success('Sucesso', 'Operação realizada com sucesso')
            this.setState({ isLoadingModal: false })
            this.closeModalDeleted()
        })
        .catch((_) => {
            toastr.error('Erro', 'Ocorreu um erro na operação')
            this.setState({ isLoadingModal: false })
        })
    }

    renderRows() {
        return this.props.companies.map(data => {
            return <tr key={data.id}>
                <th scope="row">{data.id}</th>
                <td>{data.name}</td>
                <td>{data.companyName}</td>
                <td>{data.document}</td>
                <td>
                    <Link to={`company/${data.id}`}>
                        <button type="button" className="btn btn-primary buttons-action">
                            <span className='fas fa-edit'></span>
                        </button>
                    </Link>


                    <button type="button" className="btn btn-danger" onClick={() => this.openModalDeleted(data)}>
                        <span className='fas fa-trash'></span>
                    </button>
                </td>
            </tr>
        })
    }



    render() {
        const { isOpenModalDeleted, company, isLoadingModal } = this.state
        return (
            this.props.companies ?
                <div>
                    <div className='row new-company'>
                        <ContentHeader title='Empresas' />

                        <Link to='company'>
                            <button type="button" className="btn btn-outline-primary btn-new-company">
                                Nova Empresa
                            </button>
                        </Link>
                    </div>

                    <CustomModal
                        title='Excluir'
                        subtitle={`Deseja excluir a empresa ${company.name} ?`}
                        isOpen={isOpenModalDeleted}
                        isLoading={isLoadingModal}
                        confirm={this.confirm}
                        close={this.closeModalDeleted} />

                    <div className="card">
                        <div className="card-body">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Razão Social</th>
                                        <th scope="col">CPF/CNPJ</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRows()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> :
                <div className="card">
                    <TableShimmer />
                </div>
        )
    }
}
const mapStateToProps = state => ({ companies: state.companies.companies })
const mapDispatchToProps = dispatch => bindActionCreators({ getAllCompanies, deletedById }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Companies)

