import { isNull } from '../../../utils/util'

export function validate (values) {
    const errors = {}
    if (isNull(values.name)) {
        errors.name = 'Campo obrigatório'
    }

    if (isNull(values.companyName)) {
        errors.companyName = 'Campo obrigatório'
    }

    if (isNull(values.document)) {
        errors.document = 'Campo obrigatório'
    }

    if (isNull(values.zipcode)) {
        errors.zipcode = 'Campo obrigatório'
    } else if (values.zipcode.length !== 8) {
        errors.zipcode = 'O CEP Informado é inválido'
    }

    if (isNull(values.number)) {
        errors.number = 'Campo obrigatório'
    }

    if (isNull(values.nickname)) {
        errors.nickname = 'Campo obrigatório'
    }
    
    
    return errors
}