import React from 'react'

export const SelectFieldInput = ({ input, children, label, readOnly, isRequired, name, meta: { touched, error, warning } }) => (
    <div className='form-group'>
        <label htmlFor={name}>{label} {isRequired ? <span className='field-required'>*</span> : false}</label>
        <select {...input}
            readOnly={readOnly}
            disabled={readOnly}
            className={`form-control ${touched && error ? 'is-invalid' : ''}`}>
            {children}
        </select>
        <div className="invalid-feedback">
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
)