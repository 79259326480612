import React from 'react';
import Messages from './shared/notifications/messages'
import { AuthProvider } from './template/contexts/auth';
import { AppRoutes } from './routes/routes';

function App() {
  return (
      <div className='hold-transition sidebar-mini layout-fixed'>
        <div className="wrapper">
          <AuthProvider>
            <AppRoutes />
            <Messages />
          </AuthProvider>
        </div>
      </div>
  );
}

export default App;
