import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getAll, createDevice, connectCompany, unbindCompany } from './deviceAction'
import { getAllCompaniesSummary } from '../companies/companyAction'

import {ContentHeader} from '../../components/contentHeader'
import {TableShimmer} from '../../components/tables/tableShimmer'
import { Link } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { isNull } from '../../utils/util'
import Select from 'react-select'
import CustomModal from '../../components/modals/modal'


class Devices extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoadingTable: true,
            options: [],
            device: {}
        }
        this.addNewDevice = this.addNewDevice.bind(this)
        this.openModalDeleted = this.openModalDeleted.bind(this)
        this.closeModalDeleted = this.closeModalDeleted.bind(this)
        this.confirm = this.confirm.bind(this)

    }


    componentDidMount() {
        this.props.getAll()
            .then((_) => {
                this.setState({ isLoadingTable: false })
                this.props.getAllCompaniesSummary()
                    .then((_) => {
                        const options = this.props.companies.map((company) => {
                            const op = {
                                value: company.id,
                                label: `#${company.id} - ${company.document} - ${company.name}`
                            }
                            return op
                        })
                        this.setState({ options: options })
                    })
            })
    }

    addNewDevice() {
        const { externalId } = this.state

        if (isNull(externalId)) {
            toastr.error('Erro', 'Informe o Identificador do dispositivo')
        } else {
            this.props.createDevice(externalId)
                .then((_) => {
                    toastr.success('Sucesso', 'Operação realizada com sucesso!')
                })
                .catch((_) => {
                    toastr.error('Erro', 'Ocorreu um erro')
                })

        }
    }

    onChangeDevice(value) {
        this.setState({ externalId: value })
    }

    confirm() {
        const { device } = this.state

        this.setState({ isLoadingModal: true })
        this.props.connectCompany(device.id)
        .then((_) => {
            toastr.success('Sucesso', 'Operação realizada com sucesso')
            this.setState({ isLoadingModal: false })
            this.closeModalDeleted()
        })
        .catch((_) => {
            toastr.error('Erro', 'Ocorreu um erro na operação')
            this.setState({ isLoadingModal: false })
        })
    }

    renderAddDevice() {
        return (
            <div className='add-card'>
                <div className='row'>
                    <label className='label-add-card'>Identificador</label>
                </div>
                <div className='row'>

                    <div className="input-group mb-3 col-md-4">
                        <input onChange={(e) => this.onChangeDevice(e.target.value)} type="text" className="form-control" placeholder="Identificador do dispositivo" />
                        <div className="input-group-append">
                            <button onClick={this.addNewDevice} className="btn btn-outline-secondary" type="button">Adicionar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    selectCompany(company, deviceId) {
      this.props.connectCompany(deviceId, company.value)
      .then((_) => {
          toastr.success('Sucesso', 'Operação realizada com sucesso!')
      })
      .catch((_) => {
          toastr.error('Erro', 'Ocorreu um erro')
      })
    }

    openModalDeleted(device) {
        this.setState({ isOpenModalDeleted: true, device: device })
    }

    closeModalDeleted() {
        this.setState({ isOpenModalDeleted: false })
    }


    renderRows() {
        const { options } = this.state
        return this.props.devices.map(data => {
            return <tr key={data.id}>
                <th scope="row">{data.id}</th>
                <td>{data.externalId}</td>
                <td>
                    {
                         isNull(data.company) ?
                         <div>
                            <Select options={options} onChange={(value) => this.selectCompany(value, data.id)}/>
                         </div>
                         : <Link to={'/companies/company/' + data.company.id}>
                             {data.company.document} - {data.company.companyName}
                         </Link>
                    }
                </td>
                <td>
                    {
                        isNull(data.company) ?
                            false
                            :
                            <button onClick={() => this.openModalDeleted(data)} type="button" className="btn btn-outline-danger buttons-action">
                                Desvincular Empresa
                            </button>
                    }
                </td>

            </tr>
        })
    }


    render() {
        const { isOpenModalDeleted, device, isLoadingModal, isLoadingTable } = this.state

        return (
            <div>
                <div className='row'>
                    <ContentHeader title='Dispositivos' />
                </div>
                <CustomModal
                        title='Desvincular dispositivo'
                        subtitle={`Deseja desvincular o dispositivo ${device.externalId} da empresa ${device.company?.name}?`}
                        isOpen={isOpenModalDeleted}
                        isLoading={isLoadingModal}
                        confirm={this.confirm}
                        close={this.closeModalDeleted} />
                {this.renderAddDevice()}
                {
                    !isLoadingTable ? <div className="card">
                        <div className="card-body">


                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Identificador</th>
                                        <th scope="col">Empresa</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRows()}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className="card">
                        <TableShimmer />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({ devices: state.devices.devices, companies: state.companies.companies })
const mapDispatchToProps = dispatch => bindActionCreators({ getAll, createDevice, getAllCompaniesSummary, connectCompany, unbindCompany }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Devices)
