import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {ContentHeader} from '../../../components/contentHeader'
import { getValidateCardByRfid, getCardsByDocument } from '../cardAction'
import Spinner from '../../../shared/spinners/spinner'
import { formatReal, isNull } from "../../../utils/util"
import { Link, useNavigate } from 'react-router-dom';
import { toasterError } from '../../../shared/notifications/customToaster'
import logoImg from '../../../assets/images/logo.png'

import '../cards.css'

function withParams(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}


class CardBalance extends Component {

    constructor(props) {
        super(props)
        this.state = { isLoading: false, document: null, userCards: [], isLoadingBtn: false, }

        this.changeRfid = this.changeRfid.bind(this)
        this.search = this.search.bind(this)
        this.renderCards = this.renderCards.bind(this)
    }

    componentDidMount() {
        let _self = this;

        document.addEventListener('keydown', function (evt) {
            if (_self.interval)
                clearInterval(_self.interval);
            if (evt.code === 'Enter') {
                if (_self.barcode.length) {
                    _self.changeRfid(_self.barcode);
                    _self.barcode = '';
                }
                return;
            }
            if (evt.key !== 'Shift')
                _self.barcode += evt.key;
            _self.interval = setInterval(() => _self.barcode = '', 20);
        })
    }

    changeRfid(rfid) {
        const cardRfid = rfid.replace('undefined', '')

        this.setState({ isLoading: true })
        this.props.getValidateCardByRfid(cardRfid)
            .then((_) => {
                if (!isNull(this.props.card)) {
                    this.redirectStep(cardRfid)
                }

            }).catch((_) => {
                toasterError();
                this.setState({ isLoading: false })
            })
    }

    redirectStep(rfid) {
        this.setState({ isLoading: false })
        this.props.navigate(`${rfid}`)
    }

    changeDocument(value) {
        this.setState({ document: value })
    }

    search() {
        const { document } = this.state

        if (isNull(document)) {
            toasterError()
        } else {
            this.setState({ isLoadingBtn: true })
            getCardsByDocument(document)
                .then((resp) => {
                    this.setState({ isLoadingBtn: false, userCards: resp.data })
                    if (isNull(resp.data) || resp.data.length === 0) {
                        toasterError('Nenhum cartão encontrado para o CPF: '+document);
                    }
                }).catch((_) => {
                    toasterError();
                    this.setState({ isLoadingBtn: false })
                })
        }


    }

    renderCards() {
        const { userCards = [] } = this.state

        return userCards.map(card => {
            return <div className="col-lg-3 col-6">
                <Link to={card.rfid}>
                    <div className="small-box bg-dark">
                        <div className="inner">
                            <h3>{formatReal(card.total)}</h3>
                            <p>Saldo</p>
                            <p>Cartão: {card.status === 'ACTIVE' ? 'Ativo' : 'Bloqueado'}</p>
                        </div>
                        <div className="icon">
                            <i className="fas">
                                <img className='img-logo-card' src={logoImg} alt='card'></img>
                            </i>
                        </div>
                    </div>
                </Link>
            </div>

        })
    }


    render() {
        const { isLoading, isLoadingBtn } = this.state

        return (
            <div>
                <div className='row'>
                    <ContentHeader title='Cartões' />
                </div>

                <div className="center-text-info">
                    <h1>Aproxime o cartão do leitor</h1>
                </div>

                <div className='row center-pulse'>
                    {
                        isLoading ? <Spinner /> :
                            <div className="pulse">
                                NFC
                            </div>
                    }
                </div>

                <div className="center-text-info">
                    <h1>Ou informe o CPF</h1>
                    <div className='row input-document'>
                        <div className='col-md-4'>
                            <input onChange={(e) => this.changeDocument(e.target.value)}
                                type='number' className='form-control' placeholder='informe o CPF'></input>
                        </div>
                        {isLoadingBtn ? <Spinner /> : <button onClick={this.search} className='btn btn-outline-dark'>Buscar</button>}
                        
                    </div>
                </div>
                <hr></hr>

                <div className='row'>
                    {this.renderCards()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({ card: state.cards.currentCard })
const mapDispatchToProps = dispatch => bindActionCreators({ getValidateCardByRfid, getCardsByDocument }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withParams(CardBalance))
