import { Fragment } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { PrivateRoutes } from "./index";
import { Login } from "../pages/login";
import { Core } from "../template/core";

export const AppRoutes = () => {
  return (
    <HashRouter>
      <Fragment>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<Core />} />
            <Route path="/*" element={<Core />} />
          </Route>
        </Routes>
      </Fragment>
    </HashRouter>
  );
};