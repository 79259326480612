import React, { Component } from 'react'
import { ContentHeader } from '../../components/contentHeader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getSummary, getProductsRanking, getVisitsRanking } from './dashboardAction'
import { CardSummary } from './components/CardSummary'
import { formatReal, isNull } from '../../utils/util'
import { Chart } from "react-google-charts";
import { CustomCard } from '../../components/cards/CustomCard'
import dayjs from 'dayjs'


export class DashboardByUser extends Component {

    render() {
        return <h1>Seja bem-vindo ao perfil administrador!</h1>

    }

}
class DashboardEmployee extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoadingSummary: true,
            productRanking: [],
            visitationsRanking: []
        }
    }

    componentDidMount() {
        this.props.getSummary()

        getProductsRanking()
            .then(data => {
                if (!isNull(data) && data.length > 0) {
                    let rankings = []
                    rankings.push(["Task", "Products Ranking"])
                    for (let x = 0; x < data.length; x++) {
                        const d = data[x]
                        rankings.push([d.productDescription, d.value])
                    }

                    this.setState({ productRanking: rankings })
                }
            })

        getVisitsRanking()
            .then(data => {
                if (!isNull(data) && data.length > 0) {
                    let rankings = []
                    rankings.push(["Element", "Visitas", { role: "style" }],)
                    data.reverse()
                    for (let x = 0; x < data.length; x++) {
                        const d = data[x]
                        rankings.push([dayjs(d.date).format("DD/MM"), d.quantity, "#6610f2"])
                    }
                    this.setState({ visitationsRanking: rankings })
                }
            })
    }

    mlsToLiters(value) {
        if (isNull(value)) return 0

        return `${(value / 1000).toFixed(2)}L`;
    }

    render() {
        const { totalValue, totalUsers, totalLitersConsumed, totalGrossValue } = this.props.summary
        const { isLoadingSummary, productRanking, visitationsRanking } = this.state
        return (
            <>
                <ContentHeader title='Dashboard' />
                <div className='row'>
                    <div className="alert alert-warning alert-dismissible fade show col-md-12" role="alert">
                        <strong>Atenção!</strong> Os valores abaixo são referente ao mês atual.
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>

                <div className='row'>
                    <CardSummary
                        isLoading={isLoadingSummary || 0}
                        title={formatReal(totalValue)}
                        subtitle='Valor de entrada'
                        className='bg-success'
                        icon='fa-dollar-sign' />

                    <CardSummary
                        title={formatReal(totalGrossValue || 0)}
                        subtitle='Receita bruta'
                        className='bg-success'
                        icon='fa-dollar-sign' />

                    <CardSummary
                        title={totalUsers || 0}
                        subtitle='Total de clientes'
                        className='bg-info'
                        icon='fa-users' />

                    <CardSummary
                        title={this.mlsToLiters(totalLitersConsumed || 0)}
                        subtitle='Litros consumido'
                        className='bg-warning'
                        icon='fa-tint' />
                </div>

                <div className='row'>

                    {
                        productRanking.length > 0 ?
                            <div className='col-md-6'>
                                <CustomCard title='Ranking de bebidas'>
                                    <Chart
                                        chartType="PieChart"
                                        data={productRanking}
                                        options={{
                                            title: productRanking.length === 2 ?
                                                'Resumo ultimos 15 dias.' :
                                                `Resumo ultimos 15 dias.`
                                        }}
                                        width={"100%"}
                                        height={"400px"}
                                    />
                                </CustomCard>
                            </div> : false
                    }


                    {
                        visitationsRanking.length > 0 ?
                            <div className='col-md-6'>
                                <CustomCard title='Visitas dos últimos 7 dias'>
                                    <Chart chartType="ColumnChart" width="100%" height="400px" data={visitationsRanking} />
                                </CustomCard>
                            </div> : false
                    }

                </div>

            </>
        )
    }
}

const mapStateToProps = state => ({ summary: state.dashboards.summary })
const mapDispatchToProps = dispatch => bindActionCreators({ getSummary }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(DashboardEmployee)