import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { validate } from './companyFormValidate'
import { searchAddressViaZipcode } from '../companyAction'

import {LabelAndInput} from '../../../components/inputs/labelAndInput'

import '../companies.css'
import Spinner from '../../../shared/spinners/spinner'


class CompanyForm extends Component {


    changeZipcode(value) {
        const v = parseInt(value)
        if (v.toString().length === 8) {
            this.props.searchAddressViaZipcode(v);
        }
    }


    render() {
        const { handleSubmit, address, isLoading } = this.props
        return (
            <div className="card">
                <div className="card-body">

                    <form onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-md-4">
                                <Field name='name' component={LabelAndInput}
                                    label='Nome' placeholder='Informe o nome'
                                />
                            </div>
                            <div className="col-md-4">
                                <Field name='companyName' component={LabelAndInput}
                                    label='Razão Social' placeholder='Informe a razão social' />
                            </div>

                            <div className="col-md-4">
                                <Field name='document' component={LabelAndInput} type='number'
                                    label='Documento' placeholder='Informe o CNPJ/CPF' />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <Field name='zipcode' component={LabelAndInput}
                                    label='CEP' placeholder='Informe o CEP' type='number'
                                    onChange={(e) => this.changeZipcode(e.target.value)} />
                            </div>
                            <div className="col-md-4">
                                <Field name='number' component={LabelAndInput} type='number'
                                    label='Número' placeholder='Informe o número' />
                            </div>
                            <div className="col-md-4">
                                <Field name='complement' component={LabelAndInput}
                                    label='Complemento' placeholder='Informe o complemento' />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Field name='nickname' component={LabelAndInput}
                                    label='Nickname' placeholder='Informe o Nickname da empresa' type='text' />
                            </div>
                        </div>
                        <hr />
                        <div className="card text-center">
                            <div className="card-header">
                                Endereço
                            </div>
                            <div className="card-body">
                                <p className="card-text"><b>Rua: </b> {address.street}</p>
                                <p className="card-text"><b>Bairro: </b> {address.district}</p>
                                <p className="card-text"><b>Cidade/Estado: </b> {address.city}/{address.state}</p>
                            </div>
                            <div className="card-footer text-muted">
                            </div>
                        </div>

                        {
                            isLoading ? <div className='box-footer'>
                                <Spinner />
                            </div> :
                                <div className='box-footer'>
                                    <button type='submit' className='btn btn-primary'>
                                        Salvar
                                    </button>
                                    <button type='button' className='btn btn-default btn-space'>Cancelar</button>
                                </div>
                        }

                    </form>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    initialValues: state.companies.company,
     address: state.companies.address,
     })
const mapDispatchToProps = dispatch => bindActionCreators({ searchAddressViaZipcode }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'companyForm',
    validate: validate,
})(CompanyForm))