import axios from 'axios'
import {APIConst} from '../consts'


export function get(url) { 
    setAuthorization();
    return axios.get(`${APIConst.URL_API}${url}`)
}

export function post(url, payload) { 
    setAuthorization();
    return axios.post(`${APIConst.URL_API}${url}`, payload)
}

export function put(url, payload) { 
    setAuthorization();
    return axios.put(`${APIConst.URL_API}${url}`, payload)
}

export function deleted(url) { 
    setAuthorization();
    return axios.delete(`${APIConst.URL_API}${url}`)
}

export function patch(url, payload) { 
    setAuthorization();
    return axios.patch(`${APIConst.URL_API}${url}`, payload)
}



function setAuthorization() {
    return axios.defaults
    .headers
    .common['Authorization'] = `${sessionStorage.getItem('accessToken')}`;
}