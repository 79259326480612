import React from 'react'


export const LabelAndInput = ({ input, label, type, placeholder, isRequired, readOnly, name, meta: { touched, error, warning } }) => (
    <div className='form-group'>
        <label htmlFor={name}>{label} {isRequired ? <span className='field-required'>*</span> : false}</label>
        <input {...input} className={`form-control ${touched && error ? 'is-invalid' : ''}`}
            placeholder={placeholder} type={type} readOnly={readOnly}/>
        <div className="invalid-feedback">
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
)