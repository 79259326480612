import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContentHeader } from '../../../components/contentHeader'
import { Field, reduxForm, getFormValues } from 'redux-form'

import { useParams } from 'react-router'
import { CardForm } from '../../../components/cards/CardForm'
import { LabelAndInput } from '../../../components/inputs/labelAndInput'
import { SelectFieldInput } from '../../../components/inputs/SelectField'

import { getBarrels, selectBarrel, connectToBarrel, getTapSummaryById, updateTapBarrel, resetForm } from '../tapAction'

import { validate } from '../tapValidate'
import { isNull } from '../../../utils/util'

import '../taps.css';
import { toasterError, toasterSuccess } from '../../../shared/notifications/customToaster'
import Spinner from '../../../shared/spinners/spinner'


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


class Tap extends Component {


    constructor(props) {
        super(props)
        this.persist = this.persist.bind(this)
        this.changeBarrel = this.changeBarrel.bind(this)
        this.state = {
            options: [],
            isLoadingButton: false,
            barrelSelected: null,
            isLoadingPage: !isNull(this.props.params.tapId),
            isEdit: !isNull(this.props.params.tapId)
        }
    }


    componentDidMount() {
        this.props.getBarrels()
            .then((_) => {
                const options = this.props.barrels.map((data) => {
                    const op = {
                        value: data.id,
                        label: `${data.name} - ${data.productDescription}`
                    }
                    return op
                })
                this.setState({ options: options })
                const { isEdit } = this.state

                if (isEdit) {
                    this.props.getTapSummaryById(this.props.params.tapId)
                        .then((resp) => {
                            this.changeBarrel(resp.payload.barrelId)
                            this.setState({ isLoadingPage: false })
                        })
                } else {
                    this.props.resetForm()
                }
            })


    }

    getClassByPercentage(percentage) {
        if (percentage >= 50) {
            return 'badge bg-success'
        } else if (percentage >= 30 && percentage <= 49) {
            return 'badge bg-warning'
        }
        return 'badge bg-danger'
    }

    persist(values) {
        const data = {
            barrelId: +values.barrelId,
            deviceTag: values.deviceTag,
            tapTag: values.tapTag,
            factor: parseFloat(values.factor),
            deviceId: +this.props.params.deviceId
        }
        const { isEdit } = this.state
        this.setState({ isLoadingButton: true })
        if (isEdit) {
            this.props.updateTapBarrel(this.props.params.tapId, data)
                .then((_) => {
                    toasterSuccess()
                    this.setState({ isLoadingButton: false })
                }).catch((_) => {
                    toasterError();
                    this.setState({ isLoadingButton: false })
                })
        } else {
            this.props.connectToBarrel(data)
                .then((_) => {
                    toasterSuccess()
                    this.setState({ isLoadingButton: false })
                }).catch((_) => {
                    toasterError();
                    this.setState({ isLoadingButton: false })
                })
        }
    }

    changeBarrel(barrelId) {
        if (isNull(barrelId)) {
            this.setState({ barrelSelected: null })
        } else {
            const barrelSelected = this.props.barrels.filter(b => b.id === +barrelId)[0];
            this.setState({ barrelSelected: barrelSelected })
        }
    }

    render() {
        const { options, barrelSelected, isLoadingPage, isEdit, isLoadingButton } = this.state

        return (
            <div>
                <div className='row'>
                    <ContentHeader title={isEdit ? 'Atualizar Torneira' : 'Cadastrar Torneira'} />
                </div>

                {
                    isLoadingPage ?
                        <div className='row spinner-center'><Spinner /></div> :

                        <CardForm isLoading={isLoadingButton} handleSubmit={this.props.handleSubmit}
                            click={this.persist}
                            title={'Cadastrar Torneira'}>

                            <div className="row">
                                <div className="col-md-6">
                                    <Field name='barrelId'
                                        component={SelectFieldInput}
                                        isRequired
                                        options={options}
                                        onChange={(e) => this.changeBarrel(e.target.value)}
                                        label='Barril'
                                        placeholder='selecione um barril'>
                                        <option value="">Selecione um barril</option>
                                        {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field name='tapTag' component={LabelAndInput} isRequired
                                        label='Tag - Torneira' placeholder='tag para identificação da torneira' />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <Field name='deviceTag' component={LabelAndInput} isRequired
                                        label='Tag - Monitor' placeholder='tag para identificação do monitor' />
                                </div>
                            </div>
                            <hr></hr>

                            {
                                !isNull(barrelSelected) ?
                                    <div className='row'>
                                        <div className="card tb-barrel-tap">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    Detalhes do barril
                                                </h3>
                                            </div>

                                            <div className="card-body p-0">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Porcentagem disponivel do barril</td>
                                                            <td>
                                                                <span className={this.getClassByPercentage(barrelSelected.percentageLiters)}>
                                                                    {barrelSelected.percentageLiters}%
                                                                </span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Identificador do barril</td>
                                                            <td>
                                                                {barrelSelected.name}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Produto</td>
                                                            <td>
                                                                {barrelSelected.productDescription}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Preço</td>
                                                            <td>
                                                                R${barrelSelected.price}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> : false
                            }
                        </CardForm>
                }

            </div>
        )
    }
}

Tap = reduxForm({
    form: 'tapForm',
    destroyOnUnmount: false,
    validate: validate,
})(Tap)

const mapStateToProps = state => ({ barrels: state.barrels.barrels, formValues: getFormValues('tapForm')(state) })
const mapDispatchToProps = dispatch =>
    bindActionCreators({
        getBarrels,
        selectBarrel,
        connectToBarrel,
        getTapSummaryById,
        updateTapBarrel,
        resetForm
    }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withParams(Tap))