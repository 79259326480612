import React from 'react'
import tapImg from '../../../assets/images/tap.png'
import { isNull } from '../../../utils/util'
import { Link } from 'react-router-dom'
import FactorModal from './FactorModal'


function setClassByStatus(status, statusCompany) {
    if (isNull(status) || statusCompany === 'DISABLED') {
        return 'small-box bg-dark'
    } else if (status === 'CLOSED') {
        return 'small-box bg-success'
    } else if (status === 'UNAVAILABLE') {
        return 'small-box bg-secondary'
    }
    return 'small-box bg-danger'
}

function setDescriptionByStatus(status, statusCompany) {
    if (isNull(status) || statusCompany === 'DISABLED') {
        return 'SEM BARRIL'
    } else if (status === 'CLOSED') {
        return 'Disponivel'
    } else if (status === 'UNAVAILABLE') {
        return 'Indisponível'
    }

    return 'Em uso'
}

export const CardTap = props => {
    const hasTap = !isNull(props.tap)
    return (
        <div className="col-md-6 col-sm-12 col-xs-12">
            <div className={setClassByStatus(props.tap.status, props.tap.statusCompany)}>
                <Link to={!hasTap ? `device/${props.deviceId}` : `device/${props.deviceId}/tap/${props.tap.id}`}>
                    <div className="inner">
                        <h3>{!hasTap ? 'S/I' : props.tap.tag}</h3>
                        <p>
                            {setDescriptionByStatus(props.tap.status, props.tap.statusCompany)}
                        </p>
                    </div>
                    <div className="icon">
                        <i className="fas">
                            <img className='img-card' src={tapImg} alt='barril'></img>
                        </i>
                    </div>
                </Link>

                {
                    hasTap ? <>
                        <a className="small-box-footer" data-toggle="collapse" href={`#tap${props.tap.id}`} >
                            <i className="fas fa-solid fa-chevron-down"></i>
                        </a>

                        <div className="collapse" id={`tap${props.tap.id}`}>
                            <div className='bt-actions'>
                                {props.tap.statusCompany !== 'ENABLED' ? null :
                                    <button style={{marginBottom: '10px'}} type="button" className={`btn ${props.tap.status === 'UNAVAILABLE' ? 'btn-outline-success' : 'btn-outline-danger'} col-md-12`} onClick={() => props.openModal('REMOVE', props.tap)}>
                                        {props.tap.status === 'UNAVAILABLE' ? 'Ativar torneira' : 'Desativar torneira'}
                                    </button>
                                }
                                {props.tap.statusCompany !== 'ENABLED' ? null :
                                    <FactorModal tag={props.tap.tag} tapId={props.tap.id} />
                                }
                                {
                                    props.tap.status !== 'OPEN' ? false :
                                        <button type="button" className="btn btn-outline-success col-md-12" onClick={() => props.openModal('RELEASE', props.tap)}>Fechar Torneira</button>
                                }
                            </div>
                        </div>
                    </> : <div className="small-box-footer ">
                        <i className="fas fa-solid fa-chevron-down"></i>
                    </div>
                }
            </div>
        </div>
    )
}