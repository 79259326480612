import { useState, createContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { login } from './authRepository'
import { toasterError } from '../../shared/notifications/customToaster'
import jwt_decode from "jwt-decode";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loadStorageData = () => {
      const storageToken = sessionStorage.getItem("accessToken");
      if (storageToken) {
        setUser(storageToken);
      }
    };
    loadStorageData();
  });

  async function signIn(email, password) {
    await login(email, password)
      .then((resp) => {
        const accessToken = resp.data.accessToken
        var user = jwt_decode(accessToken);

        const data = {
          'email': user.email,
          'id': user.id,
          'fullName': user.fullName,
          'rules': user.role
        }
        setUser(data);
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("user", JSON.stringify(data));
      })
      .catch((e) => {
        toasterError('Usuario ou senha inválido');
      })
  }


  function signOut() {
    sessionStorage.clear();
    setUser(null);
    return <Navigate to="/login" />;
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};