import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getAllDevicesAndTaps, changeStatusTap, close, refreshMonitor } from './tapAction'

import {ContentHeader} from '../../components/contentHeader'


import './taps.css'
import {DeviceTap} from './components/DeviceTap'
import Spinner from '../../shared/spinners/spinner'
import CustomModal from '../../components/modals/modal'
import { toasterError, toasterSuccess } from '../../shared/notifications/customToaster'
import { EmptyState } from '../../components/emptyState/EmptyState'

class Taps extends Component {


    constructor(props) {
        super(props)
        this.state = {
            isLoadingPage: true,
            isOpenModal: false,
            isLoadingModal: false,
            action: null,
            currentTap: {}
        }

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.confirm = this.confirm.bind(this)
    }


    componentDidMount() {

        this.props.getAllDevicesAndTaps()
            .then((_) => {
                this.setState({ isLoadingPage: false })
            })

    }

    openModal(action, tap) {
        this.setState({
            isOpenModal: true,
            currentTap: tap,
            action: action
        })
    }

    refreshDevice(id) {
        toasterSuccess()
        refreshMonitor(id)
    }

    renderCards() {
        if (this.props.devices.length === 0) {
            return <EmptyState />
        }
        return <div className='row'>
            {
                this.props.devices.map(dev => {
                    return <Fragment key={dev.id}>
                        <DeviceTap device={dev} openModal={this.openModal} refreshMonitor={this.refreshDevice} />
                    </Fragment>
                })
            }
        </div> 
    }

    closeModal() {
        this.setState({ isOpenModal: false })
    }

    confirm() {
        const { currentTap, action } = this.state
        this.setState({ isLoadingModal: true })

        if (action === 'REMOVE') {
            const status = currentTap.status === 'UNAVAILABLE' ? 'CLOSED' : 'UNAVAILABLE'
            this.props.changeStatusTap(currentTap.id, status)
                .then((_) => {
                    toasterSuccess()
                    this.setState({ isLoading: false, isLoadingModal: false, isOpenModal: false })
                })
                .catch((_) => {
                    toasterError();
                    this.setState({ isLoading: false, isLoadingModal: false, isOpenModal: false })
                })
        } else {
            this.props.close(currentTap.id)
                .then((_) => {
                    toasterSuccess()
                    this.setState({ isLoading: false, isLoadingModal: false, isOpenModal: false })
                })
                .catch((_) => {
                    toasterError();
                    this.setState({ isLoading: false, isLoadingModal: false, isOpenModal: false })
                })

        }

    }


    render() {
        const { isLoadingPage, currentTap, isOpenModal, isLoadingModal } = this.state
        return (
            <div>
                <CustomModal
                    title={currentTap.status === 'UNAVAILABLE' ? 'Ativar Torneira' : 'Desativar Torneira'}
                    subtitle={currentTap.status === 'UNAVAILABLE' ? `Deseja ativar a torneira ${currentTap.tag}?` : `Deseja desativar a torneira ${currentTap.tag}?`}
                    isOpen={isOpenModal}
                    isLoading={isLoadingModal}
                    confirm={this.confirm}
                    close={this.closeModal} />

                <div className='row'>
                    <ContentHeader title='Torneiras' />
                </div>
                {
                    isLoadingPage || isOpenModal ?
                        <div className='row spinner-barrel'>
                            <Spinner />
                        </div>
                        : this.renderCards()
                        
                }

            </div>
        )
    }

}

const mapStateToProps = state => ({ devices: state.taps.devices })
const mapDispatchToProps = dispatch => bindActionCreators({ getAllDevicesAndTaps, changeStatusTap, close }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Taps)
