import React from 'react'
import './floatButton.css'
import { Link } from 'react-router-dom'

export const FloatButton = props => (
    <Link to={props.path}>
        <div className="float">
            <i className="fa fa-plus my-float"></i>
        </div>
    </Link>
)