import axios from 'axios'
import { toastr } from 'react-redux-toastr'

import {CompaniesActionTypes} from './companiesActionTypes'
import { get, post, put, deleted } from '../../utils/httpCustom'

function uri() {
    return '/v1/companies'
}

export function getAllCompanies() {
    return get(uri())
        .then(resp => {
            return {
                type: CompaniesActionTypes.LIST_ALL_COMPANIES,
                payload: resp.data
            }
        })
}

export function addUser(companyId, data) {
    return post(uri().concat(`/${companyId}/addUser`), data)
}

export function getUsers(companyId) {
    return get(uri().concat(`/${companyId}/users`))
}

export function getAllCompaniesSummary() {
    return get(uri().concat("/summary"))
        .then(resp => {
            return {
                type: CompaniesActionTypes.LIST_ALL_COMPANIES,
                payload: resp.data
            }
        })
}

export function deletedById(id) {
    return deleted(uri().concat(`/${id}`))
        .then((_) => {
            return this.getAllCompanies()
        })
}

export function findById(id) {
    return get(uri().concat(`/${id}`))
        .then(resp => {
            const result = resp.data
            const data = {
                name: result.name,
                companyName: result.companyName,
                document: result.document,
                zipcode: result.address.zipcode,
                number: result.address.number,
                complement: result.address.complement,
                address: result.address,
                nickname: result.nickname
            }
            return {
                type: CompaniesActionTypes.CHANGE_FORM,
                payload: data
            }
        })
}


export function create(data) {
    return post(uri(), data)
        .then((_) => {
            toastr.success('Sucesso', 'Operação realizada com sucesso.')
            return undefined
        })
        .catch((_) => {
            toastr.error('Erro', 'Ocorreu um erro, entre em contato com suporte.')
        })
}

export function update(id, data) {
    return put(uri().concat(`/${id}`), data)
        .then((_) => {
            toastr.success('Sucesso', 'Operação realizada com sucesso.')
            return undefined
        })
        .catch((_) => {
            toastr.error('Erro', 'Ocorreu um erro, entre em contato com suporte.')
        })
}

export function searchAddressViaZipcode(value) {
    return axios.get(`https://viacep.com.br/ws/${value}/json/`)
        .then(resp => {
            const data = resp.data
            return {
                type: CompaniesActionTypes.ADDRESS_VIA_ZIPCODE,
                payload: {
                    street: data.logradouro,
                    district: data.bairro,
                    state: data.uf,
                    city: data.localidade
                }
            }
        })
        .catch((_) => {
            return {
                type: CompaniesActionTypes.ADDRESS_VIA_ZIPCODE,
                payload: {
                    street: '',
                    district: '',
                    state: '',
                    city: ''
                }
            }
        })
}

export function resetForm() {
    const data = {
        name: '',
        companyName: '',
        document: '',
        zipcode: '',
        number: '',
        complement: '',
        address: {
            street: '',
            district: '',
            state: '',
            city: ''
        }
    }
    return {
        type: CompaniesActionTypes.CHANGE_FORM,
        payload: data
    }
}
