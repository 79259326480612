import React from 'react'
import './cards.css'
import Spinner from '../../shared/spinners/spinner'

export const CardForm = props => (
    <div className="card card-primary">
        <div className="card-header">
            <h3 className="card-title">{props.title}</h3>
        </div>
        <form onSubmit={props.handleSubmit(props.click)}>
            <div className="card-body">
                {props.children}
            </div>
            <div className="card-footer">
                {props.isLoading ? <Spinner /> : <button type="submit" className="btn btn-app">Salvar</button>}                
            </div>
        </form>
    </div>
)