
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { validate } from './validate'
import {LabelAndInput} from '../../components/inputs/labelAndInput'
import Spinner from '../../shared/spinners/spinner'

import './login.css'

class LoginPage extends Component {

    constructor(props) {
        super(props)
        this.login = this.login.bind(this)
        this.state = {
            isLoading: false
        }
    }

    async login(values) {
        this.setState({ isLoading: true })
        try {
            await this.props.handleLogin(values.email, values.password)
            this.setState({ isLoading: false })
        } catch(e) {
            this.setState({ isLoading: false })
        }

    }

    render() {
        const { handleSubmit } = this.props
        const { isLoading } = this.state

        return (
            <div className="login-page">
                <div className="login-box">

                    <div className="card card-outline card-dark">
                        <div className="text-center header-login">
                            <b className="h1"><b style={{color: '#FFF7DD'}}>Happy</b><span style={{color: '#FFBA00'}}>Beer</span></b>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(this.login)}>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field name='email' component={LabelAndInput} isRequired
                                            label='Email' placeholder='seu email'
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field name='password' component={LabelAndInput} isRequired
                                            label='Senha' placeholder='sua senha' type='password'
                                        />
                                    </div>
                                </div>
                                <hr/>

                                <div className="row">
                                    {
                                        isLoading ? 
                                         <div className='col-md-12 d-flex justify-content-center'>
                                            <Spinner/>
                                         </div>
                                        : <button type="submit" className="btn col-md-12 btn-outline-dark">Entrar</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LoginPage = reduxForm({
    form: 'loginForm',
    validate: validate,
    destroyOnUnmount: false,
})(LoginPage)

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)