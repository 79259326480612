import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { validate } from './userCompanyFormValidate'
import { addUser, getUsers } from '../companyAction'

import {LabelAndInput} from '../../../components/inputs/labelAndInput'

import '../companies.css'
import Spinner from '../../../shared/spinners/spinner'
import { toasterError, toasterSuccess } from '../../../shared/notifications/customToaster'


class UserCompanyForm extends Component {

    constructor(props) {
        super(props)
        this.createUser = this.createUser.bind(this)
        this.renderRows = this.renderRows.bind(this)
        this.state = {
            isLoading: false,
            users: []
        }
    }

    createUser(values) {
        const data = {
            email: values.email,
            password: values.password,
            fullName: values.fullName
        }
        this.setState({ isLoading: true })
        addUser(this.props.companyId, data)
            .then((_) => {
                this.setState({ isLoading: false })
                toasterSuccess()
            })
            .catch((e) => {
                this.setState({ isLoading: false })
                toasterError();
            })
    }

    componentDidMount() {
        getUsers(this.props.companyId)
            .then(resp => {
                this.setState({ users: resp.data })
            })
    }

    renderRows() {
        const { users = [] } = this.state
        return users.map(u => {
            return <tr key={u.id}>
                <th scope="row">{u.id}</th>
                <td>{u.email}</td>
                <td>{u.name}</td>
                <td>
                    <button type="button" className="btn btn-link">resetar senha</button>
                </td>
            </tr>
        });

    }


    render() {
        const { handleSubmit } = this.props
        const { isLoading } = this.state
        return (
            <div className="card">
                <div className="card-body">

                    <form onSubmit={handleSubmit(this.createUser)}>

                        <div className="row">
                            <div className="col-md-4">
                                <Field name='fullName' component={LabelAndInput}
                                    label='Nome' placeholder='Informe o nome'
                                />
                            </div>
                            <div className="col-md-4">
                                <Field name='email' component={LabelAndInput}
                                    label='E-mail' placeholder='Informe o email'
                                />
                            </div>
                            <div className="col-md-4">
                                <Field name='password' component={LabelAndInput} type='password'
                                    label='Senha' placeholder='Informe a senha' />
                            </div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-end">
                                {
                                    isLoading ? <Spinner /> :
                                        <button type='submit' className='btn btn-primary'>
                                            Salvar
                                        </button>
                                }
                            </div>
                        </div>

                        <hr />
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">E-mail</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Senha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderRows()}
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'userCompanyForm',
    validate: validate,
})(UserCompanyForm))