import React, { Fragment } from 'react'
import {CardTap} from './CardTap'


function renderTaps(device, taps = [], openModal) {
    let list = taps.map(tap => {
        return <Fragment key={tap.id}>
            <CardTap openModal={openModal} deviceId={device.id} tap={tap} />
        </Fragment>
    })

    return list;
}


export const DeviceTap = (props) => {
    const { device } = props
    return (
        <div className='col-md-4'>
            <div className="card card-outline">
                <div className="card-header monitor-header">
                    <h3 className="card-title">Monitor: {device.tag || device.id}</h3>
                    <div className="card-tools">                
                        <button onClick={() => props.refreshMonitor(device.id)} type="button" className="btn btn-outline-warning">Atualizar monitor</button>
                    </div>

                </div>

                <div className="card-body">
                    <div className='row'>
                        {renderTaps(device, device.taps, props.openModal)}
                    </div>
                </div>
            </div>
        </div>
    )
}