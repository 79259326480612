import {DevicesActionType} from './devicesActionTypes'
import { get, patch, post } from '../../utils/httpCustom'

function uri() {
    return '/v1/devices'
}


export function getAll() {
    return get(uri())
        .then(resp => {
            return {
                type: DevicesActionType.LIST_ALL,
                payload: resp.data.sort((a, b) => {
                    return b.id - a.id;
                })
            }
        })
}

export function createDevice(externalId) {
    const data = {
        externalId: externalId
    }
    return post(uri(), data)
        .then((_) => {
            return getAll()
        })
}

export function connectCompany(deviceId, companyId) {
    const data = {
        companyId: companyId
    }
    return patch(uri().concat(`/${deviceId}/connect/company`), data)
        .then((_) => {
            return getAll()
        })
}

export function unbindCompany(deviceId) {
    return patch(uri().concat(`/${deviceId}/unbind/company`), {})
        .then((_) => {
            return getAll()
        })
}