import React from 'react';
import InputMask from 'react-input-mask';
 
export const PhoneInput = ({ input, label, isRequired, readOnly, name, meta: { touched, error, warning } }) => (
    <div className='form-group'>
        <label htmlFor={name}>{label} {isRequired ? <span className='field-required'>*</span> : false}</label>
            <InputMask readOnly={readOnly} {...input} mask="(99) 99999-9999" maskChar=" " className={`form-control ${touched && error ? 'is-invalid' : ''}`}/>
        <div className="invalid-feedback">
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
)