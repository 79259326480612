import {DashboardActionTypes} from './dashboardActionTypes'

const INITIAL_STATE = {summary: {}}


export const DashboardReducer = function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case DashboardActionTypes.SUMMARY:
            return { ...state, summary: action.payload }
        default:
            return state
    }
}