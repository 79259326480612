export function usersMock(email) {
    switch(email){
        case 'ronaldo.lc95@gmail.com':
            return 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJSb25hbGRvIExhdXJpbmRvIiwiaWF0IjoxNjg2NzY2NzM5LCJleHAiOjUzNTI2NzY2NzM5LCJmdWxsTmFtZSI6IlJvbmFsZG8gTGF1cmluZG8iLCJlbWFpbCI6InJvbmFsZG8ubGM5NUBnbWFpbC5jb20iLCJpZCI6Mywicm9sZSI6WyJST0xFX0JBU0lDIiwiUk9MRV9DT01QQU5ZIiwiUk9MRV9BRE1JTiJdLCJjb21wYW5pZXMiOlsxXSwic3ViIjoicm9uYWxkby5sYzk1QGdtYWlsLmNvbSJ9.gcg-qNFYkNCFrK5MR8WriKs8Dk9kQZal9Y2sPK_d2Pc'
        case 'consani@email.com':
            return 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJDT05TQU5JIiwiaWF0IjoxNjg2NzY2OTcyLCJleHAiOjUzNTI2NzY2OTcyLCJmdWxsTmFtZSI6IkNPTlNBTkkiLCJlbWFpbCI6ImNvbnNhbmlAZW1haWwuY29tIiwiaWQiOjE5LCJyb2xlIjpbIlJPTEVfQ09NUEFOWSJdLCJjb21wYW5pZXMiOls1XSwic3ViIjoiY29uc2FuaUBlbWFpbC5jb20ifQ.83TZDbg7ofUEnH9Vt5F-FRlnOf8AUTuBAEn7IRJ6aXY'
        case 'americacas@email.com':
             return 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBTUVSSUNPIiwiaWF0IjoxNjg2NzY3MDIxLCJleHAiOjUzNTI2NzY3MDIxLCJmdWxsTmFtZSI6IkFNRVJJQ08iLCJlbWFpbCI6ImFtZXJpY2FjYXNAZW1haWwuY29tIiwiaWQiOjE4LCJyb2xlIjpbIlJPTEVfQ09NUEFOWSJdLCJjb21wYW5pZXMiOls0XSwic3ViIjoiYW1lcmljYWNhc0BlbWFpbC5jb20ifQ.oQlYmpwdpptGPH82kVF_loQEB2mNFbs1L7fVplfoeQg'
         default:
            return 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJSb25hbGRvIExhdXJpbmRvIiwiaWF0IjoxNjg2NzY2NzM5LCJleHAiOjUzNTI2NzY2NzM5LCJmdWxsTmFtZSI6IlJvbmFsZG8gTGF1cmluZG8iLCJlbWFpbCI6InJvbmFsZG8ubGM5NUBnbWFpbC5jb20iLCJpZCI6Mywicm9sZSI6WyJST0xFX0JBU0lDIiwiUk9MRV9DT01QQU5ZIiwiUk9MRV9BRE1JTiJdLCJjb21wYW5pZXMiOlsxXSwic3ViIjoicm9uYWxkby5sYzk1QGdtYWlsLmNvbSJ9.gcg-qNFYkNCFrK5MR8WriKs8Dk9kQZal9Y2sPK_d2Pc'
        }
}

export function getEmailUsersMock() {
    return [
        'ronaldo.lc95@gmail.com',
        'consani@email.com',
        'americacas@email.com'
    ]
}