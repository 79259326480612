import { isNull } from '../../../utils/util'

export function validate (values) {
    const errors = {}
    if (isNull(values.name)) {
        errors.name = 'Campo obrigatório'
    }

    if (isNull(values.price)) {
        errors.price = 'Campo obrigatório'
    }

    if (isNull(values.productDescription)) {
        errors.productDescription = 'Campo obrigatório'
    }

    if (isNull(values.alcoholContent)) {
        errors.alcoholContent = 'Campo obrigatório'
    } 

    if (isNull(values.bitterness)) {
        errors.bitterness = 'Campo obrigatório'
    }

    if (isNull(values.family)) {
        errors.family = 'Campo obrigatório'
    }

    if (isNull(values.barrelVolumeId)) {
        errors.barrelVolumeId = 'Campo obrigatório'
    }

    if (isNull(values.dueDate)) {
        errors.dueDate = 'Campo obrigatório'
    }
    

    return errors
}