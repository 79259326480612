import React, { useEffect, useState } from 'react';
import { CardComponent } from '../components/CardComponent';
import { getAllCards } from '../../companies/companyRepository';
import Spinner from '../../../shared/spinners/spinner';

export const CardsPage = () => {
  const [cards, setCards] = useState([]);
  const [isLoadingPage, setLoafingPage] = useState(true);

  useEffect(() => {
    const allCards = async () => {
      try {
        const resp = await getAllCards();
        setCards(resp.data)
      } catch (error) {
      } finally {
        setLoafingPage(false)
      }
    };

    allCards();
  }, []);


  return (
    <>
      {isLoadingPage ? <div className="d-flex justify-content-center">
        <Spinner />
      </div> :
        <div className='row'>
          {cards.map(card => {
            return <div className='col-lg-3 col-6'><CardComponent card={card} /></div>
          })}
        </div>
      }
    </>
  );
};