
import { get, post, patch, put } from '../../utils/httpCustom'
import { initialize } from 'redux-form'
import { isNull } from '../../utils/util'
import {CardActionType} from './cardsActionTypes'

function uri() {
    return '/v1/cards'
}

export function getAllCards() {
    return get(uri())
        .then(resp => {
            return {
                type: CardActionType.LIST_ALL_CARDS,
                payload: resp.data.sort((a, b) => {
                    return b.id - a.id;
                })
            }
        })
}

export function getTransactionsByRfid(rfid, page, limit) {
    return get(uri().concat(`/${rfid}/transactions?page=${page}&limit=${limit}`))
        .then(resp => {
            return {
                type: CardActionType.LIST_TRANSACTIONS,
                payload: resp.data
            }
        })
}

export function getBalanceByRfid(rfid) {
    return get(uri().concat(`/${rfid}/balance/company`))
        .then(resp => {    
            return {
                type: CardActionType.CARD_BALANCE,
                payload: resp.data
            }
        })
}


export function addBalance(rfid, data) {
    return put(uri().concat(`/${rfid}/addBalance`), data)
        .then((_) => {
            return resetForm()
        })
}

export function getCardsByDocument(document) {
    return get(uri().concat(`/document/${document}`))
        .then((resp) => {
            return resp
        })
}

export function getValidateCardByRfid(rfid) {
    return get(uri().concat(`/${rfid}/validate`))
        .then(resp => {
            return {
                type: CardActionType.CURRENT_CARD,
                payload: resp.data
            }
        })
}

export function createCard(rfid) {
    const data = {
        rfid: rfid
    }
    return post(uri(), data)
        .then((_) => {
            return getAllCards();
        })
}

export function updateStatus(id, status) {
    return patch(uri().concat(`/${id}`).concat(`/${status}`), {})
        .then((_) => {
            return getAllCards();
        })
}


export function updateStatusByRfid(rfid, status) {
    return patch(uri().concat(`/${rfid}/status/${status}`), {})
        .then((_) => {
            return getBalanceByRfid(rfid);
        })
}

export function resetCard(rfid) {
    return put(uri().concat(`/${rfid}/reset`), {})
        .then((_) => {
              return {
                type: CardActionType.CARD_BALANCE,
                payload: {}
            }
        })
}


export function resetForm() {
    return initialize('transactionForm', {})
}

export function resetBalance() {
    return {
        type: CardActionType.CARD_BALANCE,
        payload: {total: 0, userDocument: '', status: ''}
    }
}

export function startForm(data) {
    return initialize('transactionForm', data)
}

export function selectType(newValue, formValue) {
    if (isNull(formValue)) {
        formValue = {type: newValue}
    } else {
        formValue.type = newValue
    }
    return initialize('transactionForm', formValue)
}

export function selectCardType(newValue, formValue) {
    if (isNull(formValue)) {
        formValue = {cardType: newValue}
    } else {
        formValue.cardType = newValue
    }
    return initialize('transactionForm', formValue)
}
