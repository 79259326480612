import { isNull } from "../../../utils/util"
import { cpf } from 'cpf-cnpj-validator'; 

export function validate(values) {
    const errors = {}
    if (isNull(values)) return;
    
    if (isNull(values.amount)) {
        errors.amount = 'Campo obrigatório'
    }

    if (isNull(values.userDocument)) {
        errors.userDocument = 'Campo obrigatório'
    } else if (!cpf.isValid(values.userDocument)) {
        errors.userDocument = 'CPF informado é inválido'
    }

    if (isNull(values.type)) {
        errors.type = 'Campo obrigatório'
    }

    if (isNull(values.reason) && (!isNull(values.type) && values.type === 'OUTPUT')) {
        errors.reason = 'Motivo é obrigatório quando o tipo de transação é RETIRADA'
    } else if (!isNull(values.reason) && values.reason.length > 200) {
        errors.reason = 'Motivo deve conter no maximo 200 caracteres.'
    }

    if (isNull(values.consumerName)) {
        errors.consumerName = 'Campo obrigatório'
    } else if (!isNull(values.consumerName) && values.consumerName.length > 120) {
        errors.consumerName = 'Nome deve conter no maximo 120 caracteres.'
    }

    if (isNull(values.consumerPhone)) {
        errors.consumerPhone = 'Campo obrigatório'
    }

    return errors
}
