import { RoutesApp } from "../routes";
import Sidebar from "../template/sidebar";
import { HeaderContext } from './header';

export const Core = () => {
    return (
      <div className='hold-transition sidebar-mini layout-fixed'>
      <div className="wrapper">
        <HeaderContext />
        <Sidebar />
        <RoutesApp />
      </div>
    </div>
    );
  };