import {TapsActionTypes} from './tapsActionTypes'
import { get, post, put, patch } from '../../utils/httpCustom'

import { getAllBarrels } from '../barrels/barrelAction'
import { initialize } from 'redux-form'
import { isNull } from '../../utils/util'

function uri() {
    return '/v1/devices'
}

function uriTaps() {
    return '/v1/taps'
}

export function refreshMonitor(id) {
    return get(uri().concat(`/${id}/refresh-monitor`))
}

export function updateFactor(id, data) {
    return patch(uriTaps().concat(`/${id}/factor`), data)
}

export function getFactorLastReading(id) {
    return get(uriTaps().concat(`/${id}/factor/last-reading`))
}

export function getAllDevicesAndTaps() {
    return get(uri().concat('/taps'))
        .then(resp => {
            return {
                type: TapsActionTypes.LIST_ALL,
                payload: isNull(resp.data) ? [] : resp.data.sort((a, b) => {
                    return a.percentageLiters - b.percentageLiters;
                })
            }
        })
}

export function connectToBarrel(data) {
    return post(uriTaps().concat('/connect/barrel'), data)
        .then((_) => {
            return initialize('tapForm', data)
        })
}

export function close(tapId) {
    return patch(uriTaps().concat(`/${tapId}/close`))
        .then((_) => {
            return getAllDevicesAndTaps()
        })
}

export function updateTapBarrel(tapId, data) {
    return put(uriTaps().concat(`/${tapId}/connect/barrel`), data)
        .then((_) => {
            return initialize('tapForm', data)
        })
}

export function getTapSummaryById(tapId) {
    return get(uriTaps().concat(`/${tapId}/summary`))
        .then((resp) => {
            return initialize('tapForm', resp.data)
        })
}

export function changeStatusTap(tapId, status) {
    return patch(uriTaps().concat(`/${tapId}/${status}`))
        .then((_) => {
            return getAllDevicesAndTaps()
        })
}

export function getBarrels() {
    return getAllBarrels();
}



export function selectBarrel(newValue, formValue) {
    if (isNull(formValue)) {
        formValue = {barrelId: newValue}
    } else {
        formValue.barrelId = newValue
    }
    return initialize('tapForm', formValue)
}

export function resetForm() {
    return initialize('tapForm', {})
}


