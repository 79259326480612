import {BarrelActionType} from './barrelsActionTypes'
import { get, post, patch, deleted, put } from '../../utils/httpCustom'
import { initialize } from 'redux-form'
import { formatDateToBR, isNull } from '../../utils/util'

function uri() {
    return '/v1/barrels'
}


export function getAllBarrels() {
    return get(uri().concat('/summary'))
        .then(resp => {
            return {
                type: BarrelActionType.LIST_ALL,
                payload: resp.data.sort((a, b) => {
                    return (a.percentageLiters - b.percentageLiters);
                })
            }
        }).catch((_) => {
            return {
                type: BarrelActionType.LIST_ALL,
                payload: []
            }
        })
}

export function createBarrel(values) {
    return post(uri(), values)
        .then((_) => {
            return resetForm()
        })
}

export function getAllVolumes() {
    return get(uri().concat('/volumes'))
        .then(resp => {
            return {
                type: BarrelActionType.LIST_ALL_VOLUMES,
                payload: resp.data.sort((a, b) => {
                    return b.id - a.id;
                })
            }
        })
}

export function notifyExchange(id) {
    return patch(uri().concat(`/${id}/notifyExchange`))
    .then((_) => {
        return getAllBarrels()
    })
}

export function replace(toId, fromId) {
    return put(uri().concat(`/${toId}/replace/${fromId}`))
    .then((_) => {
        return resetForm()
    })
}


export function deletedById(id) {
    return deleted(uri().concat(`/${id}`))
    .then((_) => {
        return getAllBarrels()
    })
}

export function update(id, data) {
    return put(uri().concat(`/${id}`), data)
}


export function findById(id) {
    return get(uri().concat(`/${id}`))
    .then((resp) => {
        if (!isNull(resp.data.dueDate)) {
            resp.data.dueDate = formatDateToBR(resp.data.dueDate)
        }
        return initialize('barrelForm', resp.data)
    })
}

export function changeForm(data) {
    return initialize('barrelForm', data)
}

export function resetForm() {
    return initialize('barrelForm', {})
}

