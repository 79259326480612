export function isNull(value) {
    return value === null || value === undefined || value === '';
}

export function formatDateToUS(date) {
    const split = date.split('/')
    return [split[2], split[1], split[0]].join('-');
}

export function formatDateToBR(date) {
    const split = date.split('-')
    return [split[2], split[1], split[0]].join('/');
}

export function formatReal(number) {

    if (Number.isNaN(number) || !number) return `R$ 0,00`;

    if (typeof number === "string") { // n1
        number = Number(number);
    }

    let res;
    const config = { style: 'currency', currency: 'BRL' };

    res = number.toLocaleString('pt-BR', config)

    const needComma = number => number <= 1000;
    if (needComma(number)) {
        res = res.toString().replace(".", ",");
    }
    return res;
}

export function formatCurrencyInputBR(value) {
    return value.toString()
        .replace('R$', '')
        .replace('.', '')
        .replace(',', '.')
}

export function formatPhone(telefone) {
    if (telefone == null) return '';
    // Remover qualquer caractere não numérico do número de telefone
    const numeroLimpo = telefone.replace(/\D/g, '');
  
    // Aplicar a formatação desejada
    const formatoTelefone = '($1) $2-$3';
    const telefoneFormatado = numeroLimpo.replace(/(\d{2})(\d{5})(\d{4})/, formatoTelefone);
  
    return telefoneFormatado;
  }
  