import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getAllBarrels, notifyExchange, deletedById } from './barrelAction'

import { ContentHeader } from '../../components/contentHeader'
import { CardBarrel } from './components/CardBarrel'
import { FloatButton } from '../../components/buttons/FloatButton'
import Spinner from '../../shared/spinners/spinner'
import { EmptyState } from '../../components/emptyState/EmptyState'

import CustomModal from '../../components/modals/modal'

import './barrels.css'
import { toasterError, toasterSuccess } from '../../shared/notifications/customToaster'
import { useNavigate } from 'react-router'

function withParams(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}

class Barrels extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isLoadingModal: false,
            isOpenModal: false,
            barrelSelected: { name: '', liters: 0 },
            modal: { title: '', subtitle: '', action: '' },
        }

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.confirm = this.confirm.bind(this)

        this.deleted = this.deleted.bind(this)
        this.toBarrelReplace = this.toBarrelReplace.bind(this)
    }

    componentDidMount() {
        this.props.getAllBarrels()
            .then((_) => {
                this.setState({ isLoading: false })
            }).catch((e) => {
                this.setState({ isLoading: false })
            })
    }

    toBarrelReplace(id) {
        this.props.navigate(`${id}/replace`)
    }

    renderBarrels() {
        if (this.props.barrels.length === 0) {
            return <EmptyState />
        }
        return <div className='row'>
            {
                this.props.barrels.map(barrel => {
                    return <Fragment key={barrel.id}>

                        <CardBarrel barrel={barrel}
                            openModal={this.openModal}
                            toBarrelReplace={this.toBarrelReplace}
                            percentage={barrel.percentageLiters} />

                    </Fragment>
                })
            }
        </div>
    }

    openModal(action, barrel) {
        let title = ''
        let subtitle = ''

        if (action === 'REGISTER') {
            title = 'Registrar troca de barril'
            subtitle = `Deseja registrar a troca do barril (${barrel.name})?`
        } else {
            title = 'Excluir barril'
            subtitle = `Deseja excluir o barril (${barrel.name})?`
        }

        this.setState({
            isOpenModal: true,
            barrelSelected: barrel,
            modal: {
                title: title,
                action: action,
                subtitle: subtitle
            }
        })
    }

    closeModal() {
        this.setState({ isOpenModal: false })
    }


    deleted() {
        const { barrelSelected } = this.state
        this.setState({ isLoadingModal: true })
        this.props.deletedById(barrelSelected.id)
            .then((_) => {
                toasterSuccess()
                this.setState({ isLoading: false, isLoadingModal: false, isOpenModal: false })
            })
            .catch((_) => {
                toasterError('Verifique se o barril possui torneira atrelada.');
                this.setState({ isLoading: false, isLoadingModal: false, isOpenModal: false })
            })
    }

    confirm() {
        const { barrelSelected } = this.state
        this.setState({ isLoadingModal: true })
        this.props.notifyExchange(barrelSelected.id)
            .then((_) => {
                toasterSuccess()
                this.setState({ isLoading: false, isLoadingModal: false, isOpenModal: false })
            })
            .catch((_) => {
                toasterError();
                this.setState({ isLoading: false, isLoadingModal: false, isOpenModal: false })
            })
    }

    render() {

        const { isLoading, isOpenModal, isLoadingModal, modal } = this.state

        return (
            <div>
                <CustomModal
                    title={modal.title}
                    subtitle={modal.subtitle}
                    isOpen={isOpenModal}
                    isLoading={isLoadingModal}
                    confirm={modal.action === 'REGISTER' ? this.confirm : this.deleted}
                    close={this.closeModal} />


                <div className='row'>
                    <ContentHeader title='Barris' />
                </div>

                {!isLoading && !isOpenModal ?

                    this.renderBarrels()
                    :
                    <div className='row spinner-barrel'>
                        <Spinner />
                    </div>
                }

                <FloatButton path='barrel' />
            </div>
        )
    }
}

const mapStateToProps = state => ({ barrels: state.barrels.barrels })
const mapDispatchToProps = dispatch => bindActionCreators({ getAllBarrels, notifyExchange, deletedById }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withParams(Barrels))
